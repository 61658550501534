// NEEDED TO FEED REVIEW ITS PARAMS

import Review from "../Review";

import { useParams } from "react-router";
export default function ReviewLoader() {
  const params = useParams();
  return (
    <div className="review-box">
      <a className="new-prequal" href="../../">
        Solicitar Pre-Calificacion Nueva
      </a>
      <Review preQId={params.preQId} />
    </div>
  );
}
