import { useState } from "react";
import ClientEditForm from "./ClientEditForm";
import "./ClientButton.css";
import "./ClientInfoForm.css";

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}
const ClientButton = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const editHandler = () => {
    setIsEditing(true);
  };

  const removeHandler = () => {
    props.setClients((clients) =>
      clients.filter((client) => client.id !== props.client.id)
    );
    console.log(props.clients);
  };

  return (
    <>
      {isEditing ? (
        <div className="edit-form">
          <ClientEditForm
            locale={props.locale}
            clients={props.clients}
            client={props.client}
            setIsEditing={setIsEditing}
            setClients={props.setClients}
          />
        </div>
      ) : (
        <>
          <p className="nameLastName">
            {props.client.firstName} {props.client.lastName}{" "}
            <span className="edit-button modify-info" onClick={editHandler}>
              ✎
            </span>
            <span className="edit-button delete" onClick={removeHandler}>
              🗑
            </span>
          </p>
          <br />
          <p id="phoneEmail">
            ☎ {formatPhoneNumber(props.client.phoneNumber)} <br /> 📧
            {props.client.email}
          </p>
        </>
      )}
    </>
  );
};

export default ClientButton;
