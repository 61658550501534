import { useState } from "react";
import validator from "validator";
const CreditInfoForm = (props) => {
  for (let i = 0; i < props.preQual.clients.length; i++) {
    if (
      validator.isInt(String(props.preQual.clients[i].creditScore), {
        min: 620,
        max: 850,
      })
    ) {
      props.setCompleted(true);
    } else {
      props.setCompleted(false);
      break;
    }
  }
  const [creditScore, setCreditScore] = useState(props.client.creditScore);
  let creditScoreValid = validator.isInt(String(creditScore), {
    min: 620,
    max: 850,
  });
  const creditScoreChangeHandler = (event) => {
    if (Number(event.target.value)) {
      props.client.creditScore = Number(event.target.value);
      setCreditScore(Number(event.target.value));
    } else {
      setCreditScore(event.target.value);
    }
  };
  props.client.creditScore = creditScore;
  return (
    <>
      <p className="question-text">
        {props.locale === "en"
          ? "What is the credit score of "
          : "¿Cuál es la puntuación de crédito de"}{" "}
        {props.client.firstName} {props.client.lastName}?
      </p>

      <br />
      <input
        name={creditScore}
        value={creditScore}
        placeholder="xxx"
        maxLength="3"
        style={{
          backgroundColor: !creditScoreValid
            ? String(creditScore).length >= 3
              ? "red"
              : ""
            : "",
          textAlign: "center",
        }}
        onChange={creditScoreChangeHandler}
        type="input"
      />
      <p className="bottom-disclaimer">
        {String(creditScore).length > 2
          ? !creditScoreValid
            ? creditScore < 620
              ? props.locale === "en"
                ? "The minimum credit score for a mortgage loan is 620"
                : "La empírica mínima para un préstamo hipotecario es 620."
              : props.locale === "en"
              ? "Credit scores are numbers between 300 and 850"
              : "Las puntuaciones de crédito son números entre 300 y 850."
            : ""
          : ""}
      </p>
    </>
  );
};

export default CreditInfoForm;
