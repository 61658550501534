import { useState } from "react";
import JobButton from "./JobButton";
import JobInfoFormForm from "./JobInfoFormForm";

const JobInfoForm = (props) => {
  props.setCompleted(false);
  const [jobs, setJobs] = useState(props.client.jobs);
  props.client.jobs = jobs;
  const addJobHandler = () => {
    setIsEditing(true);
  };

  const [noIncome, setNoIncome] = useState(props.client.noIncome);
  const noIncomeHandler = (event) => {
    if (noIncome) {
      setNoIncome(false);
    } else {
      setNoIncome(true);
    }
  };
  props.client.noIncome = noIncome;
  for (let i = 0; i < props.preQual.clients.length; i++) {
    if (
      !props.preQual.clients[i].noIncome &&
      !props.preQual.clients[i].jobs.length > 0
    ) {
      props.setCompleted(false);
      break;
    } else {
      props.setCompleted(true);
    }
  }
  const [isEditing, setIsEditing] = useState(false);

  if (props.client.jobs.length === 0) {
    if (isEditing) {
      return (
        <JobInfoFormForm
          locale={props.locale}
          id={Math.random()}
          setIsEditing={setIsEditing}
          client={props.client}
          setJobs={setJobs}
          setCompleted={[props.setCompleted]}
        />
      );
    } else {
      return (
        <>
          <button className="job-info-button" onClick={addJobHandler}>
            {props.locale === "en"
              ? "Add income for "
              : "Añadir informacion de ingreso"}{" "}
            {props.client.firstName}
          </button>
          <div className="no-debt-checkbox-label" onClick={noIncomeHandler}>
            <input
              className="no-debt-checkbox"
              type="checkbox"
              checked={noIncome ? "checked" : ""}
              onChange={noIncomeHandler}
            />{" "}
            {"  "}
            {props.client.firstName}
            {props.locale === "en" ? "has no income." : "no tiene ingreso."}
          </div>
        </>
      );
    }
  } else {
    props.setCompleted(true);
    return (
      <>
        <>
          {isEditing ? (
            <JobInfoFormForm
              locale={props.locale}
              key={Math.random()}
              setIsEditing={setIsEditing}
              client={props.client}
              setJobs={setJobs}
            />
          ) : (
            <button className="job-info-button2" onClick={addJobHandler}>
              {props.locale === "en"
                ? "Add additional income for "
                : "Añadir ingreso adicional"}{" "}
              {props.client.firstName}
              <br />
            </button>
          )}
          {props.client.jobs.map((job) => (
            <JobButton
              locale={props.locale}
              job={job}
              key={job.id}
              jobs={jobs}
              setJobs={setJobs}
            />
          ))}
        </>
      </>
    );
  }
};

export default JobInfoForm;
