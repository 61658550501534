import { useState, useContext } from "react";
import "./LoanPurpose.css";
import Question from "../UI/Question.js";
import PreQContext from "../../PreQContext";

const buttons = [
  { value: "Compra", text: "Comprar" },
  {
    value: "Refinanciamiento",
    text: "Refinanciar",
  },
];
const enbuttons = [
  { value: "Compra", text: "Buy" },
  {
    value: "Refinanciamiento",
    text: "Refinance",
  },
];

export default function LoanPurpose(props) {
  const value = useContext(PreQContext);
  value.setCompleted(false);
  const [purpose, setPurpose] = useState(value.preQual.purpose);
  const clickHandler = (event) => {
    setPurpose(event.target.value);
    value.preQual.purpose = event.target.value;
    value.setCurrSlide(value.slides[value.currSlide].next);
  };
  if (purpose !== "") {
    value.setCompleted(true);
  }
  return (
    <div className="purpose-box">
      <Question
        key={Math.random()}
        questionText={
          value.locale === "en" ? "I'm looking to:" : "Estoy buscando:"
        }
        value={purpose}
        buttons={value.locale === "en" ? enbuttons : buttons}
        clickHandler={clickHandler}
      />
    </div>
  );
}
