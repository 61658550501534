import { useState } from "react";
import { createPortal } from "react-dom";
import Objects from "../../../Objects";
import validator from "validator";
const DebtEditForm = (props) => {
  let typeValid = true,
    paymentValid = true;
  const [enteredType, setEnteredType] = useState(props.debt.type);
  const [enteredPayment, setEnteredPayment] = useState(props.debt.payment);
  const [enteredBalance, setEnteredBalance] = useState(props.debt.balance);
  const [deferredAnswer, setDeferredAnswer] = useState(props.debt.deferred);
  const [isDeferred, setIsDeferred] = useState(props.debt.isDeferred);
  let studentLoanPayment =
    "Le tenemos que considerar un pago de " +
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(Number(enteredBalance) * 0.005) +
    " mensual.";
  let enStudentLoanPayment =
    "We have to consider a monthly payment of " +
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(Number(enteredBalance) * 0.005) +
    " .";

  const submitHandler = (event) => {
    event.preventDefault();
    if (typeValid && paymentValid) {
      props.setIsEditing(false);

      let newDebt = new Objects.Debt(
        enteredPayment,
        enteredType,
        enteredBalance,
        Math.random(),
        deferredAnswer,
        isDeferred
      );
      props.setDebts((debts) =>
        debts.filter((debt) => debt.id !== props.debt.id)
      );
      props.setDebts((prevState) => {
        return [newDebt, ...prevState];
      });
      setEnteredType("undefined");
      setEnteredPayment(0);
      setEnteredBalance(0);
      typeValid = false;
      paymentValid = false;
    } else {
      document.getElementsByClassName("modal")[0].style.animation =
        ".10s ease-out 0s 2 reverse running modalShakeInPlace";
      setTimeout(() => {
        document.getElementsByClassName("modal")[0].style.animation = "";
      }, 1000);
      if (!paymentValid) {
        document.getElementById("typeselect").style.backgroundColor = "red";
      }
      if (!typeValid) {
        document.getElementById("paymentInput").style.backgroundColor = "red";
      }
    }
  };

  const typeChangeHandler = (event) => {
    setEnteredType(event.target.value);
    typeValid = event.target.value !== "undefined";
  };
  const paymentChangeHandler = (event) => {
    paymentValid = validator.isNumeric(event.target.value);
    if (paymentValid) {
      setEnteredPayment(Number(event.target.value));
    } else {
      setEnteredPayment(event.target.value);
    }
  };
  const balanceChangeHandler = (event) => {
    setEnteredBalance(Number(event.target.value));
    setEnteredPayment(Number(event.target.value) * 0.005);
  };

  const cancelHandler = (event) => {
    event.preventDefault();
    props.setIsEditing(false);
    typeValid = false;
    paymentValid = false;
  };
  const deferredChangeHandler = (event) => {
    console.log(event);
    setDeferredAnswer(event.target.value);
    event.target.value === "Si" ? setIsDeferred(true) : setIsDeferred(false);
  };
  if (props.locale === "en") {
    return createPortal(
      <div className="overlay">
        <form className="modal" onSubmit={submitHandler}>
          <div className="form-box debt">
            <p>
              Account type:
              <select
                value={enteredType}
                onChange={typeChangeHandler}
                id="typeselect"
                style={{ backgroundColor: typeValid ? "white" : "" }}
              >
                <option value="undefined">--Select--</option>
                <option value="Tarjeta de Credito">Credit Card</option>
                <option value="Prestamo de Auto">Auto Loan</option>
                <option value="Prestamo Personal">Personal Loan</option>
                <option value="Prestamo Estudiantil">Student Loan</option>
                <option value="Pension">Alimony</option>
                <option value="Hipoteca">Mortgage</option>
              </select>
              <div>
                {enteredType === "Prestamo Estudiantil" ? (
                  <div>
                    Is the loan deferred?
                    <select
                      value={deferredAnswer}
                      onChange={deferredChangeHandler}
                    >
                      <option value="Si">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                ) : (
                  ""
                )}
                {isDeferred ? (
                  <p>
                    Total balance:
                    <input
                      value={enteredBalance}
                      onChange={balanceChangeHandler}
                    />
                  </p>
                ) : (
                  ""
                )}
              </div>
            </p>
            {isDeferred ? (
              enStudentLoanPayment
            ) : (
              <p>
                Monthly payment:
                <input
                  value={enteredPayment}
                  id="paymentInput"
                  style={{
                    backgroundColor: !paymentValid
                      ? enteredPayment !== 0 && "red"
                      : "",
                  }}
                  onChange={paymentChangeHandler}
                />
              </p>
            )}

            <button type="submit">Save</button>
            <button onClick={cancelHandler}>Cancel</button>
          </div>
        </form>
      </div>,
      document.body
    );
  } else {
    return createPortal(
      <div className="overlay">
        <form className="modal" onSubmit={submitHandler}>
          <div className="form-box debt">
            <p>
              Tipo de cuenta:
              <select value={enteredType} onChange={typeChangeHandler}>
                <option value="Tarjeta de Credito">Tarjeta de Credito</option>
                <option value="Prestamo de Auto">Prestamo de Auto</option>
                <option value="Prestamo Personal">Prestamo Personal</option>
                <option value="Prestamo Estudiantil">
                  Prestamo Estudiantil
                </option>
                <option value="Hipoteca">Hipoteca</option>
              </select>
            </p>
            {isDeferred ? (
              studentLoanPayment
            ) : (
              <p>
                Pago Mensual:
                <input
                  value={enteredPayment}
                  id="paymentInput"
                  style={{
                    backgroundColor: !paymentValid
                      ? enteredPayment !== 0 && "red"
                      : "",
                  }}
                  onChange={paymentChangeHandler}
                />
              </p>
            )}

            <div>
              {enteredType === "Prestamo Estudiantil" ? (
                <div>
                  Se encuentra diferido?
                  <select
                    value={deferredAnswer}
                    onChange={deferredChangeHandler}
                  >
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </select>
                </div>
              ) : (
                ""
              )}
              {isDeferred ? (
                <p>
                  Balance Adeudado:
                  <input
                    value={enteredBalance}
                    onChange={balanceChangeHandler}
                  />
                </p>
              ) : (
                ""
              )}
            </div>
            <button type="submit">Guardar Cambios</button>
            <button onClick={cancelHandler}>Cancelar</button>
          </div>
        </form>
      </div>,
      document.body
    );
  }
};

export default DebtEditForm;
