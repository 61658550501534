import CreditInfoForm from "./CreditInfoForm";
import { useContext } from "react";
import PreQContext from "../../PreQContext";
import Card from "../UI/Card";
const CreditInfoPage = (props) => {
  const value = useContext(PreQContext);

  return (
    <div className="credit-box">
      {value.preQual.clients.map((client) => (
        <Card className="credit-score-card">
          <CreditInfoForm
            locale={value.locale}
            preQual={value.preQual}
            client={client}
            setCompleted={value.setCompleted}
            id={Math.random()}
          />
        </Card>
      ))}
    </div>
  );
};
export default CreditInfoPage;
