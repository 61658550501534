import { useContext } from "react";
import ClientButton from "../Questions/ClientInfo/ClientButton";
import PreQContext from "../../PreQContext";

export default function CompletedClientButton(props) {
  const value = useContext(PreQContext);
  function debtClickHandler() {
    value.setCurrSlide(8);
  }
  function jobClickHandler() {
    value.setCurrSlide(7);
  }
  return (
    <>
      <ClientButton
        locale={props.locale}
        client={props.client}
        clients={props.clients}
        setClients={props.setClients}
      />
      <br />
      <p className="final-header">
        {props.locale === "en" ? "Credit Score:" : "Puntuación de crédito:"}{" "}
        {props.client.creditScore}
      </p>
      <>
        {props.client.jobs.length > 0 ? (
          <p className="final-header">
            {props.locale === "en" ? "Income" : "Ingreso:"}{" "}
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.client.income)}{" "}
            {props.locale === "en" ? "monthly" : "mensual"}{" "}
            <span
              className="edit-button modify-info-job"
              onClick={jobClickHandler}
            >
              ✎
            </span>
          </p>
        ) : (
          <p className="job-info">
            {" "}
            {props.client.firstName}{" "}
            {props.locale === "en" ? "has no income." : "no tiene ingresos."}{" "}
            <span
              className="edit-button modify-info-job"
              onClick={jobClickHandler}
            >
              ✎
            </span>
          </p>
        )}
      </>
      <>
        {props.client.debts.length > 0 ? (
          <p className="final-header">
            {props.locale === "en" ? "Debts:" : "Deuda:"}{" "}
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.client.debt)}{" "}
            {props.locale === "en" ? "monthly" : "mensual"}{" "}
            <span
              className="edit-button modify-info-job"
              onClick={debtClickHandler}
            >
              ✎
            </span>
          </p>
        ) : (
          <p className="final-header">
            {" "}
            {props.client.firstName}{" "}
            {props.locale === "en" ? "has no debts." : "no tiene deudas."}{" "}
            <span
              className="edit-button modify-info-job"
              onClick={debtClickHandler}
            >
              ✎
            </span>
          </p>
        )}
      </>
    </>
  );
}
