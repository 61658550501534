import { useState } from "react";
import { createPortal } from "react-dom";
import Objects from "../../../Objects";
import validator from "validator";
const date = new Date();
let salaryValid = true,
  YOEValid = true,
  titleValid = true;
const shakeModal = () => {
  document.getElementsByClassName("modal")[0].style.animation =
    ".10s ease-out 0s 2 reverse running modalShakeInPlace";
  setTimeout(() => {
    document.getElementsByClassName("modal")[0].style.animation = "";
  }, 1000);
};

const JobEditForm = (props) => {
  console.log(props.job);
  const [enteredSalary, setSalary] = useState(props.job.salary);
  const [enteredYOE, setEnteredYOE] = useState(props.job.yearsOfExperience);
  const [enteredType, setEnteredType] = useState(props.job.type);
  const [enteredFrequency, setEnteredFrequency] = useState(
    props.job.paymentFrequency
  );
  const [enteredTitle, setEnteredTitle] = useState(props.job.title);
  const [pensionType, setPensionType] = useState(props.job.pensionType);
  const [returns, setReturns] = useState({
    firstReturn: props.job.firstReturn,
    secondReturn: props.job.secondReturn,
    returnAverage: props.job.returnAverage,
  });
  const [hourly, setHourly] = useState({
    rate: props.job.hourlyRate,
    hours: props.job.hoursWorked,
    salary: props.job.salary,
  });

  const salaryChangeHandler = (event) => {
    if (Number(event.target.value)) {
      setSalary(Number(event.target.value));
      salaryValid = validator.isNumeric(event.target.value);
    } else {
      setSalary(event.target.value);
    }
  };
  const yoeChangeHandler = (event) => {
    YOEValid = validator.isNumeric(event.target.value);
    YOEValid
      ? setEnteredYOE(Number(event.target.value))
      : setEnteredYOE(event.target.value);
  };
  const typeChangeHandler = (event) => {
    setEnteredType(event.target.value);
    if (event.target.value !== "Nomina") {
      titleValid = true;
      YOEValid = true;
      setEnteredTitle(event.target.value);
    }
    if (event.target.value === "Servicios Profesionales") {
      salaryValid = true;
      setEnteredFrequency("Mensual");
    }
  };
  const frequencyChangeHandler = (event) => {
    setEnteredFrequency(event.target.value);
  };
  const titleChangeHandler = (event) => {
    setEnteredTitle(event.target.value);
    titleValid = validator.isAlphanumeric(event.target.value, "en-US");
  };
  const pensionChangeHandler = (event) => {
    setPensionType(event.target.value);
  };

  const firstReturnChangeHandler = (event) => {
    let newVal = Number(event.target.value);
    setReturns((prevState) => {
      let newAVG = (prevState.secondReturn + newVal) / 24;
      return { ...prevState, firstReturn: newVal, returnAverage: newAVG };
    });
  };
  const secondReturnChangeHandler = (event) => {
    let newVal = Number(event.target.value);
    setReturns((prevState) => {
      let newAVG = (prevState.firstReturn + newVal) / 24;
      return { ...prevState, secondReturn: newVal, returnAverage: newAVG };
    });
  };

  const hourlyRateChangeHandler = (event) => {
    if (
      validator.isNumeric(event.target.value.toString()) &&
      validator.isNumeric(hourly.hours.toString()) &&
      hourly.hours > 0
    ) {
      salaryValid = true;
      setHourly((prevState) => {
        return {
          ...prevState,
          rate: Number(event.target.value),
          salary: (Number(event.target.value) * hourly.hours * 52) / 12,
        };
      });
    } else {
      setHourly((prevState) => {
        return { ...prevState, rate: event.target.value };
      });
    }
  };
  const hoursWorkedChangeHandler = (event) => {
    if (
      validator.isNumeric(event.target.value.toString()) &&
      validator.isNumeric(hourly.rate.toString()) &&
      hourly.rate > 0
    ) {
      salaryValid = true;
      setHourly((prevState) => {
        return {
          ...prevState,
          hours: Number(event.target.value),
          salary: (Number(event.target.value) * hourly.rate * 52) / 12,
        };
      });
    } else {
      setHourly((prevState) => {
        return { ...prevState, hours: event.target.value };
      });
    }
  };
  const submitHandler = (event) => {
    event.preventDefault();
    if (enteredType === "Pension" && pensionType === "undefined") {
      shakeModal();
      document.getElementById("pensionType").style.backgroundColor = "red";
    } else if (titleValid && YOEValid && salaryValid) {
      let actualIncome = 0;
      if (enteredType === "Servicios Profesionales") {
        if (returns.firstReturn === 0 || returns.secondReturn === 0) {
          shakeModal();
          if (!Number(returns.firstReturn) || returns.firstReturn === 0) {
            document.getElementById("firstReturn").style.backgroundColor =
              "red";
          }
          if (!Number(returns.firstReturn || returns.secondReturn === 0)) {
            document.getElementById("secondReturn").style.backgroundColor =
              "red";
          }
          return;
        }
        actualIncome = returns.returnAverage;
      } else {
        actualIncome = enteredSalary;
      }
      let newJob = new Objects.Job(
        enteredTitle,
        actualIncome,
        enteredYOE,
        enteredType,
        enteredFrequency,
        Math.random(),
        returns.firstReturn,
        returns.secondReturn,
        returns.returnAverage,
        pensionType,
        hourly.rate,
        hourly.hours
      );
      props.setJobs((jobs) => jobs.filter((job) => job.id !== props.job.id));
      props.setJobs((prevState) => {
        return [...prevState, newJob];
      });
      props.setIsEditing(false);
    } else {
      shakeModal();
      if (!salaryValid) {
        document.getElementById("salary").style.backgroundColor = "red";
      }
      if (!YOEValid) {
        document.getElementById("YOE").style.backgroundColor = "red";
      }
      if (!titleValid) {
        document.getElementById("title").style.backgroundColor = "red";
      }
    }
  };

  const cancelHandler = (event) => {
    event.preventDefault();
    props.setIsEditing(false);
  };
  if (props.locale === "en") {
    return createPortal(
      <div className="overlay">
        <form className="modal" onSubmit={submitHandler}>
          <div className="form-box job">
            <p>
              Tipo of income:{" "}
              <select value={enteredType} onChange={typeChangeHandler}>
                <option value="undefined">--Select--</option>
                <option value="Nomina">Payroll</option>
                <option value="Pension">Pension</option>
                <option value="Seguro Social">Social Security</option>
                <option value="Servicios Profesionales">Self Employed</option>
                <option value="Por Hora">Hourly</option>
              </select>
            </p>
            {enteredType === "Nomina" ? (
              <>
                <p>
                  Employer:{" "}
                  <input
                    value={enteredTitle}
                    onChange={titleChangeHandler}
                    id="title"
                    type="text"
                    style={{
                      backgroundColor:
                        !titleValid && enteredTitle.length > 0 && "red",
                    }}
                  ></input>
                </p>
              </>
            ) : (
              <></>
            )}

            {enteredType === "Nomina" && (
              <p>
                Years of Experience:
                <input
                  value={enteredYOE}
                  label="años"
                  id="YOE"
                  onChange={yoeChangeHandler}
                  style={{
                    backgroundColor:
                      !YOEValid && enteredYOE.length > 0 && "red",
                  }}
                />
              </p>
            )}
            {enteredType === "Servicios Profesionales" ? (
              <>
                <p>
                  Net income on {date.getFullYear() - 1} tax return:
                  <input
                    value={returns.firstReturn}
                    id="firstReturn"
                    onChange={firstReturnChangeHandler}
                    style={{
                      backgroundColor:
                        returns.firstReturn > 0 && "rgba(51, 58, 86, 1)",
                    }}
                  />
                </p>
                <p>
                  Net income on {date.getFullYear() - 2} tax return:
                  <input
                    value={returns.secondReturn}
                    id="secondReturn"
                    onChange={secondReturnChangeHandler}
                    style={{
                      backgroundColor:
                        returns.secondReturn > 0 && "rgba(51, 58, 86, 1)",
                    }}
                  />
                </p>
                <p>
                  Average monthly income:{" "}
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(returns.returnAverage)}
                </p>
              </>
            ) : (
              <p>
                {enteredFrequency !== "Por Hora" && (
                  <>
                    Salary:
                    <input
                      value={enteredSalary}
                      id="salary"
                      placeholder="000"
                      onChange={salaryChangeHandler}
                      style={{
                        backgroundColor: !salaryValid
                          ? enteredSalary.length > 0 && "red"
                          : "",
                      }}
                    />
                  </>
                )}
                Payment frequency:
                <select
                  value={enteredFrequency}
                  onChange={frequencyChangeHandler}
                >
                  <option value="undefined">--Select--</option>
                  <option value="Mensual">Monthly</option>
                  <option value="Quincenal">Semimonthly</option>
                  <option value="Semanal">Weekly</option>
                  <option value="Bi-Semanal">Bi-Weekly</option>
                  <option value="Anual">Annual</option>
                  <option value="Por Hora">Hourly</option>
                </select>
                {enteredFrequency === "Por Hora" && (
                  <>
                    <p>
                      Hourly Rate:
                      <input
                        id="rate"
                        value={hourly.rate}
                        onChange={hourlyRateChangeHandler}
                        style={{
                          backgroundColor:
                            Number(hourly.rate) &&
                            hourly.rate !== undefined &&
                            "rgba(51, 58, 86, 1)",
                        }}
                      />
                    </p>
                    <p>
                      Hours Worked:
                      <input
                        id="hours"
                        value={hourly.hours}
                        onChange={hoursWorkedChangeHandler}
                        style={{
                          backgroundColor:
                            Number(hourly.hours) &&
                            hourly.hours !== undefined &&
                            "rgba(51, 58, 86, 1)",
                        }}
                      />
                    </p>
                  </>
                )}
              </p>
            )}
            {enteredType === "Pension" ? (
              <>
                <p>
                  What kind of pension do you receive?
                  <select
                    id="pensionType"
                    value={pensionType}
                    style={{
                      backgroundColor: pensionType !== "undefined" && "",
                    }}
                    onChange={pensionChangeHandler}
                  >
                    <option value="undefined">--Select--</option>
                    <option value="Alimenticia">Alimony</option>
                    <option value="Seguro Social">Social Security</option>
                    <option value="Otra">Other</option>
                  </select>
                </p>
              </>
            ) : (
              <></>
            )}

            <button type="submit">Save Changes</button>
            <button onClick={cancelHandler}>Cancel</button>
            {enteredType === "Servicios Profesionales" && (
              <p className="bottom-warning">
                In order to consider self-employment income, you must have filed
                two tax returns.
              </p>
            )}
            {enteredType === "Pension" ? (
              pensionType === "Alimenticia" ? (
                <p className="bottom-warning">
                  Alimony income must have been received to a bank account,
                  without interruption, for 12 months.
                </p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
        </form>
      </div>,
      document.body
    );
  } else {
    return createPortal(
      <div className="overlay">
        <form className="modal" onSubmit={submitHandler}>
          <div className="form-box job">
            <p>
              Tipo de empleo:{" "}
              <select value={enteredType} onChange={typeChangeHandler}>
                <option value="Nomina">Nomina</option>
                <option value="Pension">Pension</option>
                <option value="Seguro Social">Seguro Social</option>
                <option value="Servicios Profesionales">
                  Servicios Profesionales
                </option>
              </select>
            </p>
            {enteredType === "Nomina" ? (
              <>
                <p>
                  Patrono:{" "}
                  <input
                    value={enteredTitle}
                    onChange={titleChangeHandler}
                    id="title"
                    type="text"
                    style={{
                      backgroundColor:
                        !titleValid && enteredTitle.length > 0 && "red",
                    }}
                  ></input>
                </p>
              </>
            ) : (
              <></>
            )}

            {enteredType === "Nomina" && (
              <p>
                Años en linea empleo:
                <input
                  value={enteredYOE}
                  label="años"
                  id="YOE"
                  onChange={yoeChangeHandler}
                  style={{
                    backgroundColor:
                      !YOEValid && enteredYOE.length > 0 && "red",
                  }}
                />
              </p>
            )}
            {enteredType === "Servicios Profesionales" ? (
              <>
                <p>
                  Ganancia rendida en planilla {date.getFullYear() - 1}:
                  <input
                    value={returns.firstReturn}
                    id="firstReturn"
                    onChange={firstReturnChangeHandler}
                    style={{
                      backgroundColor:
                        returns.firstReturn > 0 && "rgba(51, 58, 86, 1)",
                    }}
                  />
                </p>
                <p>
                  Ganancia rendida en planilla {date.getFullYear() - 2}:
                  <input
                    value={returns.secondReturn}
                    id="secondReturn"
                    onChange={secondReturnChangeHandler}
                    style={{
                      backgroundColor:
                        returns.secondReturn > 0 && "rgba(51, 58, 86, 1)",
                    }}
                  />
                </p>
                <p>
                  Salario promedio:{" "}
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(returns.returnAverage)}
                </p>
              </>
            ) : (
              <p>
                {enteredFrequency !== "Por Hora" && (
                  <>
                    Salario:
                    <input
                      value={enteredSalary}
                      id="salary"
                      placeholder="000"
                      onChange={salaryChangeHandler}
                      style={{
                        backgroundColor: !salaryValid
                          ? enteredSalary.length > 0 && "red"
                          : "",
                      }}
                    />
                  </>
                )}
                <p>
                  {" "}
                  Frequencia de pago:{" "}
                  {/* {enteredFrequency !== "Anual"
                    ? enteredSalary === 10250
                      ? ">10000"
                      : enteredSalary
                    : enteredSalary === 500250
                    ? ">500000+"
                    : enteredSalary}{" "} */}
                  <select
                    value={enteredFrequency}
                    onChange={frequencyChangeHandler}
                  >
                    <option value="Mensual">Mensual</option>
                    <option value="Quincenal">Quincenal</option>
                    <option value="Semanal">Semanal</option>
                    <option value="Bi-Semanal">Bi-Semanal</option>
                    <option value="Anual">Anual</option>
                    <option value="Por Hora">Por Hora</option>
                  </select>
                </p>
                {enteredFrequency === "Por Hora" && (
                  <>
                    <p>
                      Pago Por Hora:
                      <input
                        id="rate"
                        value={hourly.rate}
                        onChange={hourlyRateChangeHandler}
                        style={{
                          backgroundColor:
                            Number(hourly.rate) &&
                            hourly.rate !== undefined &&
                            "rgba(51, 58, 86, 1)",
                        }}
                      />
                    </p>
                    <p>
                      Horas Trabajadas:
                      <input
                        id="hours"
                        value={hourly.hours}
                        onChange={hoursWorkedChangeHandler}
                        style={{
                          backgroundColor:
                            Number(hourly.hours) &&
                            hourly.hours !== undefined &&
                            "rgba(51, 58, 86, 1)",
                        }}
                      />
                    </p>
                  </>
                )}
              </p>
            )}
            {enteredType === "Pension" ? (
              <>
                <p>
                  Que tipo de pension recibe?
                  <select
                    id="pensionType"
                    value={pensionType}
                    style={{
                      backgroundColor: pensionType !== "undefined" && "",
                    }}
                    onChange={pensionChangeHandler}
                  >
                    <option value="undefined">--Seleccione--</option>
                    <option value="Alimenticia">Alimenticia</option>
                    <option value="Seguro Social">Seguro Social</option>
                    <option value="Otra">Otra</option>
                  </select>
                </p>
              </>
            ) : (
              <></>
            )}

            <button type="submit">Guardar Cambios</button>
            <button onClick={cancelHandler}>Cancelar</button>
            {enteredType === "Servicios Profesionales" && (
              <p className="bottom-warning">
                Para poder considerar ingresos de servicios profesionales debe
                haber rendido 2 planillas.
              </p>
            )}
            {enteredType === "Pension" ? (
              pensionType === "Alimenticia" ? (
                <p className="bottom-warning">
                  El ingreso de pension alimenticia debe haberse recibido sin
                  interrupcion por los ultimos 12 meses a una cuenta bancaria.
                </p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
        </form>
      </div>,
      document.body
    );
  }
};

export default JobEditForm;
