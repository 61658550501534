import "./MoveButtons.css";
import NextButton from "./NextButton";
import { useContext } from "react";
import PreQContext from "../../PreQContext";
import PrevButton from "./PrevButton";
const MoveButtons = (props) => {
  const value = useContext(PreQContext);
  var currSlide = value.currSlide;
  return (
    <nav className="movebuttons-mobile">
      <PrevButton
        title={value.locale === "en" ? "Previous" : "Anterior"}
        classOne={"movebuttons-left"}
        classTwo={"prev"}
      />
      <div
        className="arrow-container"
        style={{
          opacity: value.currSlide >= 10 ? "0" : "",
        }}
      >
        <div
          className={
            currSlide > 0
              ? "arrow-completed  first-arrow"
              : "arrow  first-arrow"
          }
        ></div>
        <div className={currSlide > 1 ? "arrow-completed" : "arrow"}></div>
        <div className={currSlide > 2 ? "arrow-completed" : "arrow"}></div>
        <div className={currSlide > 3 ? "arrow-completed" : "arrow"}></div>
        <div className={currSlide > 4 ? "arrow-completed" : "arrow"}></div>
        <div className={currSlide > 5 ? "arrow-completed" : "arrow"}></div>
        <div className={currSlide > 6 ? "arrow-completed" : "arrow"}></div>
        <div className={currSlide > 7 ? "arrow-completed" : "arrow"}></div>
        <div className={currSlide > 8 ? "arrow-completed" : "arrow"}></div>
        <div className={currSlide > 9 ? "arrow-completed" : "arrow"}></div>
      </div>

      <NextButton
        title={value.locale === "en" ? "Next" : "Continuar"}
        classOne={"movebuttons-right"}
        classTwo={"next"}
      />
    </nav>
  );
};

export default MoveButtons;
