//Prequalification Letter with download button.

import { useContext } from "react";
import { createPortal } from "react-dom";
import PreQContext from "../PreQContext";
import equalhousing from "../images/equalhousing.jpg";
import "./PreQLetter.css";
import logo from "../images/hmblogo.jpg";
import signature2 from "../images/signature2.jpg";
import qrcode from "yaqrcode";

export default function PreQLetter(props) {
  const { originator, clients, maxFHALAMT, maxRHSLAMT, VA, id } =
    useContext(PreQContext).preQual;
  const printPDF = () => {
    const link = document.createElement("a");
    window.open("https://api.primerhogarpr.com:8443/download/" + id);
  };
  const date = new Date().toDateString();
  const clientCount = clients.length;
  const preQualQrCode = qrcode(
    "https://frolicking-dasik-7b5571.netlify.app/review/" + id,
    {
      size: 100,
    }
  );
  let clientList = clients[0].firstName + " " + clients[0].lastName + " ";
  if (clientCount > 1) {
    clientList += "& ";
    for (let i = 1; i < clientCount - 1; i++) {
      clientList += clients[i].firstName + " " + clients[i].lastName + " & ";
    }
    clientList +=
      clients[clientCount - 1].firstName +
      " " +
      clients[clientCount - 1].lastName;
  }
  const FHAmsg = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(maxFHALAMT);
  const FHArehabmsg = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(maxFHALAMT - 35000);
  const RHSmsg = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(maxRHSLAMT);
  let loanType = "FHA 203";
  if (maxRHSLAMT > 35000) {
    loanType += " / RHS";
  }
  if (VA) {
    loanType += " / VA";
  }
  const exitHandler = () => {
    props.setSubmitted(false);
  };

  return createPortal(
    <div className="overlay">
      <div className="preq-letter">
        <div className="qrCode-box">
          <p>Scan here to see the data this pre- qualification was based on:</p>
          <img className="qrCode" alt="QRCode" src={preQualQrCode} />
        </div>
        <button className="exit-button" onClick={exitHandler}>
          X
        </button>
        <button className="print-button" onClick={printPDF}>
          DESCARGAR Carta
        </button>
        <img className="HMBLogo" src={logo} alt="Home Mortgage Bankers logo" />
        <img
          className="signature"
          src={originator === "661" ? signature2 : signature2}
          alt="MLO Signature"
        />
        <img
          className="equalhousing"
          src={equalhousing}
          alt="equal housing logo"
        />
        <p className="preq-date">{date}</p>
        <div className="preq-body">
          <p className="pre-q-clients"> Client : {clientList}</p>
          <p className="pre-q-loantypes">Loan Type : {loanType}</p>
          <p className="casid">Case ID: {id}</p>
          <br />
          <br />
          <p>
            {clientList} {clientCount > 1 ? "have" : "has"} been properly
            pre-qualifed for a Purchase Contract with a sales price of:
          </p>
          <br />
          <div className="fha-box">
            <p
              style={{ textAlign: "left", marginLeft: "5%", fontWeight: "700" }}
            >
              FHA 203(b) :
            </p>
            <p style={{ marginLeft: "1.5%" }}>{FHAmsg}</p>
          </div>
          <div className="fha-box">
            <p
              style={{ textAlign: "left", marginLeft: "5%", fontWeight: "700" }}
            >
              FHA 203(k) :
            </p>
            <p style={{ marginLeft: "1.5%" }}>{FHArehabmsg}</p>
          </div>
          {maxRHSLAMT > 35000 && (
            <div className="rhs-box">
              <p
                style={{
                  textAlign: "left",
                  marginLeft: "5%",
                  fontWeight: "700",
                }}
              >
                Rural :
              </p>
              <p style={{ marginLeft: "1.5%" }}>{RHSmsg}</p>
            </div>
          )}
          {VA && (
            <div className="va-box">
              <p
                style={{
                  textAlign: "left",
                  marginLeft: "5%",
                  fontWeight: "700",
                }}
              >
                VA :
              </p>
              <p style={{ marginLeft: "1.5%" }}>{FHAmsg}</p>
            </div>
          )}
          <br />
          <br />
          <p>
            The loan is subject to compliance with credit history and documents.
            This is not a final approval. These amounts do not take into
            consideration any government assistance the{" "}
            {clientCount > 1 ? "clients" : "client"} may be receiving.
          </p>
          <br />
          <p>
            {" "}
            For any questions regarding this pre-qualification letter please
            contact us at the number specified below.
          </p>
        </div>
      </div>
    </div>,
    document.body
  );
}
