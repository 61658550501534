import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ReviewLoader from "./components/UI/ReviewLoader";
import reportWebVitals from "./reportWebVitals";
import PreQLetterDownloader from "./components/PreQLetterDownloader";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/661",
    element: <App originator={"661"} letter = {true} />, //661 => Edward -- No Letter
    errorElement: <>App Error Page Here</>,
  },
  {
    path: "/660",
    element: <App originator={"660"} letter = {true} />, //660 => Rafael -- No Letter
    errorElement: <>App Error Page Here</>,
  },
  {
    path: "/review/:preQId",
    element: <ReviewLoader />, // Generates a review page w/ data entered by client assigned to this ID
    errorElement: <>Review Error Page Here</>,
  },
  {
    path: "/preqletter/:preQId",
    element: <PreQLetterDownloader />, // Generates letter to be downloaded
    errorElement: <>Downloader Error Page Here</>,
  },
  {
    path: "/internal/use/only/daU8KLs3kq", // Main App w/ letter
    element: <App letter={true} />,
    errorElement: <>App Error Page Here</>,
  },
  { path: "/", element: <App letter = {true} />, errorElement: <>App Error Page Here</> }, //Main App -- No Letter
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
