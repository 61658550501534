import "./Buttons.css";

const Buttons = (props) => {
  return props.buttons.map((button) => (
    <button
      value={button.value}
      key={Math.random()}
      onClick={props.clickHandler}
      className={
        button.value === props.currentValue
          ? "selectedbutton " + button.text
          : button.text
      }
    >
      {button.text}
    </button>
  ));
};

export default Buttons;
