import { useContext } from "react";
import PreQContext from "../../PreQContext";
import chevron from "../../images/chevron-right.png";
const NextButton = (props) => {
  const value = useContext(PreQContext);
  const nextClickHandler = () => {
    if (value.completed && value.currSlide !== 11)
      value.setCurrSlide(value.slides[value.currSlide].next);
  };
  return (
    <>
      <div
        style={{
          opacity: value.completed
            ? value.currSlide !== 10
              ? "100"
              : "0"
            : "0",
        }}
        className={props.classOne}
        onClick={nextClickHandler}
      >
        <div className={props.classTwo}>{props.title}</div>
        <img
          className="chevron-next"
          src={chevron}
          alt="chevron pointing right"
        />
      </div>
    </>
  );
};
export default NextButton;
