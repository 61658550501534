import { useState } from "react";
import JobEditForm from "./JobEditForm.js";
const translatejobfrequency = (frequency) => {
  switch (frequency) {
    case "Mensual":
      return "Monthly";
    case "Quincenal":
      return "Semi-Monthly";
    case "Semanal":
      return "Weekly";
    case "Bi-Semanal":
      return "Bi-Weekly";
    case "Anual":
      return "Annualy";
    case "Por Hora":
      return "Monthly";
  }
};
const JobButton = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const editHandler = () => {
    setIsEditing(true);
  };

  const removeHandler = () => {
    props.setJobs((jobs) => jobs.filter((job) => job.id !== props.job.id));
    console.log(props.jobs);
  };
  return (
    <>
      {isEditing ? (
        <JobEditForm
          locale={props.locale}
          jobs={props.jobs}
          job={props.job}
          setIsEditing={setIsEditing}
          setJobs={props.setJobs}
        />
      ) : (
        <div className="job-box">
          <span className="job-info">
            {props.job.title === "Servicios Profesionales"
              ? props.locale === "en"
                ? "Self-Employed"
                : "Serv Prof."
              : props.job.title}
            :{" "}
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.job.salary)}{" "}
            {props.locale === "en"
              ? translatejobfrequency(props.job.paymentFrequency)
              : props.job.paymentFrequency === "Por Hora"
              ? "Mensual"
              : props.job.paymentFrequency}
          </span>{" "}
          <span className="edit-button modify-info-job" onClick={editHandler}>
            ✎
          </span>
          <span className="edit-button delete-job" onClick={removeHandler}>
            🗑
          </span>
        </div>
      )}
    </>
  );
};

export default JobButton;
