import "./Arrows.css";
import spain_flag from "../../images/spain_flag.png";
import us_flag from "../../images/us_flag.png";
const Header = (props) => {
  const localeHandler = (event) => {
    props.setLocale(event.target.alt);
  };
  return (
    <header className="header">
      <div className="logo-box"></div>
      <div className="locale-box">
        <p className="locale-span">
          <img
            alt="es"
            className="locale"
            src={spain_flag}
            onClick={localeHandler}
          ></img>
          Español
        </p>
        <p className="locale-span">
          <img
            alt="en"
            className="locale"
            src={us_flag}
            onClick={localeHandler}
          ></img>
          English
        </p>
      </div>
      <p className="title">
        {props.locale === "en"
          ? "Prequalification Application"
          : "Solicitud de Pre-Calificación"}
      </p>
      <p className="NMLSID">NMLS: 1716907</p>
    </header>
  );
};

export default Header;
