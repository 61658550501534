import { useState, useContext } from "react";
import { createPortal } from "react-dom";
import PreQContext from "../../PreQContext";
import PreQLetter from "../PreQLetter";
import CompletedClientButton from "./CompletedClientButton";
import FinalCard from "./FinalCard";
import WillCall from "./WillCall";

const FinalPage = (props) => {
  async function updateHandler() {
    await fetch("https://api.primerhogarpr.com:8443/record/update/update", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(value.preQual),
    }).then(() => {
      if (value.letter && value.preQual.maxFHALAMT > 45000) {
        setSubmitted(true);
      } else {
        value.setCurrSlide(11);
      }
    });
  }

  async function saveHandler() {
    console.log("savehandler called");
    await fetch("https://api.primerhogarpr.com:8443/add", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(value.preQual),
    }).then(() => {
      if (value.letter) {
        setSubmitted(true);
      } else {
        value.setCurrSlide(11);
      }
    });
  }
  const value = useContext(PreQContext);
  if (value.preQual.clients.length === 0) {
    value.setCurrSlide(2);
  }
  value.preQual.prequalify();

  const [clients, setClients] = useState(value.preQual.clients);
  const [submitted, setSubmitted] = useState(false);
  value.preQual.clients = clients;
  if (clients.length > 0) {
    value.setCompleted(true);
  } else {
    value.setCompleted(false);
  }
  async function submitHandler() {
    console.log("SUBMIT RAN");
    await fetch(
      "https://api.primerhogarpr.com:8443/record/" + value.preQual.id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      var data = res.json();
      data.then((value) => {
        if (value !== null) {
          updateHandler();
        } else {
          saveHandler();
        }
      });
    });
  }
  if (submitted) {
    if (value.preQual.maxFHALAMT >= 45000) {
      return createPortal(
        <PreQLetter preQual={value.preQual} setSubmitted={setSubmitted} />,
        document.body
      );
    } else {
      value.setCurrSlide(11);
    }
  } else
    return (
      <div className="final-container">
        <div className="final-box">
          {value.preQual.clients.map((client) => (
            <>
              <FinalCard key={Math.random()}>
                <CompletedClientButton
                  locale={value.locale}
                  client={client}
                  setClients={setClients}
                  clients={clients}
                />
              </FinalCard>
            </>
          ))}
          <button className="final-submit-button" onClick={submitHandler}>
            {value.locale === "en" ? "Submit" : "Completar"}
          </button>
        </div>
      </div>
    );
};

export default FinalPage;
