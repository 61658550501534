import Buttons from "./Buttons.js";
import "./Question.css";

const Question = (props) => {
  return (
    <>
      {props.questionText === "" ? (
        <></>
      ) : (
        <>
          <p className="question-text">{props.questionText}</p>
          <br />
          <br />
        </>
      )}
      <Buttons
        key={Math.random()}
        buttons={props.buttons}
        currentValue={props.value}
        clickHandler={props.clickHandler}
      />
    </>
  );
};

export default Question;
