/*
This component generates a page with the information entered by a 
previous client. It make an API call to the server and receives back
a JSON with the data assiged to that id.
 */

import { Component } from "react";
import FinalCard from "./UI/FinalCard";
import DisplayClientButton from "./UI/DisplayClientButton";
class Review extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      result: {},
    };
  }

  componentDidMount() {
    //API call to get data to build this.
    fetch("https://api.primerhogarpr.com:8443/record/" + this.props.preQId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            result,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    const { error, isLoaded, result } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      console.log(result);
      return (
        <>
          {result.clients.map((client) => (
            <FinalCard key={Math.random()}>
              <DisplayClientButton
                key={Math.random()}
                client={client}
                setClients={() => {
                  console.log("setClients"); // component expects the prop.
                }}
                clients={result.clients}
              />
            </FinalCard>
          ))}
        </>
      );
    }
  }
}

export default Review;
