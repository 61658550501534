import { useState } from "react";
import { createPortal } from "react-dom";
import Objects from "../../../Objects";
import validator from "validator";
let typeValid = false,
  paymentValid = false;
const DebtInfoFormForm = (props) => {
  const [enteredType, setEnteredType] = useState("undefined");
  const [enteredPayment, setEnteredPayment] = useState();
  const [enteredBalance, setEnteredBalance] = useState(0);
  const [deferredAnswer, setDeferredAnswer] = useState("No");
  const [isDeferred, setIsDeferred] = useState(false);
  let studentLoanPayment =
    "Le tenemos que considerar un pago de " +
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(Number(enteredBalance) * 0.005) +
    " mensual.";

  let enStudentLoanPayment =
    "We have to consider a monthly payment of " +
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(Number(enteredBalance) * 0.005) +
    " .";

  const submitHandler = (event) => {
    event.preventDefault();
    if (paymentValid && typeValid) {
      let newDebt = new Objects.Debt(
        enteredPayment,
        enteredType,
        enteredBalance,
        Math.random(),
        deferredAnswer,
        isDeferred
      );
      props.setDebts((prevState) => {
        return [newDebt, ...prevState];
      });
      setEnteredType("undefined");
      setEnteredPayment(0);
      setEnteredBalance(0);
      typeValid = false;
      paymentValid = false;
    } else {
      document.getElementsByClassName("modal")[0].style.animation =
        ".10s ease-out 0s 2 reverse running modalShakeInPlace";
      setTimeout(() => {
        document.getElementsByClassName("modal")[0].style.animation = "";
      }, 1000);
      if (!typeValid) {
        document.getElementById("typeselect").style.backgroundColor = "red";
      }
      if (!paymentValid) {
        document.getElementById("paymentInput").style.backgroundColor = "red";
      }
    }
  };
  const typeChangeHandler = (event) => {
    setEnteredType(event.target.value);
    typeValid = event.target.value !== "undefined";
  };
  const paymentChangeHandler = (event) => {
    paymentValid = validator.isNumeric(event.target.value);
    if (paymentValid) {
      setEnteredPayment(Number(event.target.value));
    } else {
      setEnteredPayment(event.target.value);
    }
  };

  const balanceChangeHandler = (event) => {
    if (Number(event.target.value)) {
      setEnteredBalance(Number(event.target.value));
      setEnteredPayment(Number(event.target.value) * 0.005);
      paymentValid = true;
    } else {
      setEnteredBalance(Number(event.target.value));
    }
  };

  const cancelHandler = (event) => {
    event.preventDefault();
    props.setIsEditing(false);
    typeValid = false;
    paymentValid = false;
  };
  const deferredChangeHandler = (event) => {
    setDeferredAnswer(event.target.value);
    setIsDeferred(event.target.value === "Si");
  };
  if (props.locale === "en") {
    return createPortal(
      <div className="overlay">
        <form className="modal" onSubmit={submitHandler}>
          <div className="form-box debt">
            {props.client.debts.length > 0 && (
              <>
                <p className="question-text">
                  Would you like to add another debt?
                </p>
                <br />
              </>
            )}
            <p>
              Account type:
              <select
                id="typeselect"
                value={enteredType}
                style={{ backgroundColor: typeValid ? "white" : "" }}
                onChange={typeChangeHandler}
              >
                <option value="undefined">--Select--</option>
                <option value="Tarjeta de Credito">Credit Card</option>
                <option value="Prestamo de Auto">Auto Loan</option>
                <option value="Prestamo Personal">Personal Loan</option>
                <option value="Prestamo Estudiantil">Student Loan</option>
                <option value="Pension">Alimony</option>
                <option value="Hipoteca">Mortgage</option>
              </select>
            </p>
            <div>
              {enteredType === "Prestamo Estudiantil" ? (
                <div>
                  Is the loan deferred?
                  <select
                    value={deferredAnswer}
                    onChange={deferredChangeHandler}
                  >
                    <option value="Si">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              ) : (
                ""
              )}
              {isDeferred && (
                <p>
                  Total Balance:{" "}
                  <input
                    placeholder="000"
                    value={enteredBalance}
                    onChange={balanceChangeHandler}
                  />
                </p>
              )}

              {isDeferred ? (
                enStudentLoanPayment
              ) : (
                <p>
                  Monthly Payment:
                  <input
                    value={enteredPayment}
                    id="paymentInput"
                    placeholder="000"
                    style={{
                      backgroundColor: !paymentValid
                        ? enteredPayment && "red"
                        : "",
                    }}
                    onChange={paymentChangeHandler}
                  />
                </p>
              )}
            </div>
            <p></p>

            <button type="submit">Save</button>
            <button onClick={cancelHandler}>
              {props.client.debts.length > 0 ? "Done" : "Cancel"}{" "}
            </button>
          </div>
        </form>
      </div>,
      document.body
    );
  } else {
    return createPortal(
      <div className="overlay">
        <form className="modal" onSubmit={submitHandler}>
          <div className="form-box debt">
            {props.client.debts.length > 0 && (
              <>
                <p className="question-text">
                  ¿Desea añadir alguna otra deuda?
                </p>
                <br />
              </>
            )}
            <p>
              Tipo de cuenta:
              <select
                id="typeselect"
                value={enteredType}
                style={{ backgroundColor: typeValid ? "white" : "" }}
                onChange={typeChangeHandler}
              >
                <option value="undefined">--Seleccionar--</option>
                <option value="Tarjeta de Credito">Tarjeta de Credito</option>
                <option value="Prestamo de Auto">Prestamo de Auto</option>
                <option value="Prestamo Personal">Prestamo Personal</option>
                <option value="Prestamo Estudiantil">
                  Prestamo Estudiantil
                </option>
                <option value="Pension">Pension</option>
                <option value="Hipoteca">Hipoteca</option>
              </select>
            </p>
            <div>
              {enteredType === "Prestamo Estudiantil" ? (
                <div>
                  Se encuentra diferido?
                  <select
                    value={deferredAnswer}
                    onChange={deferredChangeHandler}
                  >
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </select>
                </div>
              ) : (
                ""
              )}
              {isDeferred && (
                <p>
                  Balance Adeudado:{" "}
                  <input
                    placeholder="000"
                    value={enteredBalance}
                    onChange={balanceChangeHandler}
                  />
                </p>
              )}

              {isDeferred ? (
                studentLoanPayment
              ) : (
                <p>
                  Pago Mensual:
                  <input
                    value={enteredPayment}
                    id="paymentInput"
                    placeholder="000"
                    style={{
                      backgroundColor: !paymentValid
                        ? enteredPayment && "red"
                        : "",
                    }}
                    onChange={paymentChangeHandler}
                  />
                </p>
              )}
            </div>
            <p></p>

            <button type="submit">Guardar</button>
            <button onClick={cancelHandler}>Terminar</button>
          </div>
        </form>
      </div>,
      document.body
    );
  }
};

export default DebtInfoFormForm;
