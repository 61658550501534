/*
This component generates a prequalification letter with the information 
entered by a previous client. It make an API call to the server and
receives back a JSON with the data assiged to that id.
 */

import equalhousing from "../images/equalhousing.jpg";
import "./PreQLetter.css";
import logo from "../images/hmblogo.jpg";
import signature from "../images/signature.png";
import signature2 from "../images/signature2.jpg";
import qrcode from "yaqrcode";
import { Component } from "react";
class PreQLetterGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      result: {},
    };
  }

  componentDidMount() {
    console.log(
      "fetching: " +
        "https://api.primerhogarpr.com:8443/record/" +
        this.props.preQId
    );
    fetch("https://api.primerhogarpr.com:8443/record/" + this.props.preQId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          this.setState({
            isLoaded: true,
            result,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    const { error, isLoaded, result } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      const { originator, clients, maxFHALAMT, maxRHSLAMT, VA, id } = result;
      const date = new Date().toDateString();
      const clientCount = clients.length;
      const preQualQrCode = qrcode(
        "https://frolicking-dasik-7b5571.netlify.app/review/" + id,
        {
          size: 100,
        }
      );
      let clientList = clients[0].firstName + " " + clients[0].lastName + " ";
      if (clientCount > 1) {
        clientList += "& ";
        for (let i = 1; i < clientCount - 1; i++) {
          clientList +=
            clients[i].firstName + " " + clients[i].lastName + " & ";
        }
        clientList +=
          clients[clientCount - 1].firstName +
          " " +
          clients[clientCount - 1].lastName;
      }
      const FHAmsg = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(maxFHALAMT);
      const FHArehabmsg = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(maxFHALAMT - 35000);
      const RHSmsg = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(maxRHSLAMT);
      let loanType = "FHA 203";
      if (maxRHSLAMT > 35000) {
        loanType += " / RHS";
      }
      if (VA) {
        loanType += " / VA";
      }

      return (
        <div className="preq-letter download">
          <div className="qrCode-box">
            <p>
              Scan here to see the data this pre- qualification was based on:
            </p>
            <img className="qrCode" alt="QRCode" src={preQualQrCode} />
          </div>
          <img
            className="HMBLogo"
            src={logo}
            alt="Home Mortgage Bankers logo"
          />
          <img
            className="signature"
            src={originator === "661" ? signature : signature2}
            alt="MLO Signature"
          />
          <img
            className="equalhousing"
            src={equalhousing}
            alt="equal housing logo"
          />
          <p className="preq-date">{date}</p>
          <div className="preq-body">
            <p className="pre-q-clients"> Client : {clientList}</p>
            <p className="pre-q-loantypes">Loan Type : {loanType}</p>
            <p className="casid">Case ID: {id}</p>
            <br />
            <br />
            <p>
              {clientList} {clientCount > 1 ? "have" : "has"} been properly
              pre-qualifed for a Purchase Contract with a sales price of:
            </p>
            <br />
            <div className="fha-box">
              <p
                style={{
                  textAlign: "left",
                  marginLeft: "5%",
                  fontWeight: "700",
                }}
              >
                FHA 203(b) :
              </p>
              <p style={{ marginLeft: "1.5%" }}>{FHAmsg}</p>
            </div>
            <div className="fha-box">
              <p
                style={{
                  textAlign: "left",
                  marginLeft: "5%",
                  fontWeight: "700",
                }}
              >
                FHA 203(k) :
              </p>
              <p style={{ marginLeft: "1.5%" }}>{FHArehabmsg}</p>
            </div>
            {maxRHSLAMT > 35000 && (
              <div className="rhs-box">
                <p
                  style={{
                    textAlign: "left",
                    marginLeft: "5%",
                    fontWeight: "700",
                  }}
                >
                  Rural :
                </p>
                <p style={{ marginLeft: "1.5%" }}>{RHSmsg}</p>
              </div>
            )}
            {VA && (
              <div className="va-box">
                <p
                  style={{
                    textAlign: "left",
                    marginLeft: "5%",
                    fontWeight: "700",
                  }}
                >
                  VA :
                </p>
                <p style={{ marginLeft: "1.5%" }}>{FHAmsg}</p>
              </div>
            )}
            <br />
            <br />
            <p>
              The loan is subject to compliance with credit history and
              documents. This is not a final approval. These amounts do not take
              into consideration any government assistance the{" "}
              {clientCount > 1 ? "clients" : "client"} may be receiving.
            </p>
            <br />
            <p>
              {" "}
              For any questions regarding this pre-qualification letter please
              contact us at the number below.
            </p>
          </div>
        </div>
      );
    }
  }
}
export default PreQLetterGenerator;
