import { useState, useContext } from "react";
import Question from "../UI/Question.js";
import PreQContext from "../../PreQContext.js";
const statusButtons = [
  { value: "casado", text: "Casada / o" },
  { value: "no casado", text: "No Casada / o" },
];

const enStatusButtons = [
  { value: "casado", text: "Married" },
  { value: "no casado", text: "Unmarried" },
];

const MaritalStatus = (props) => {
  const value = useContext(PreQContext);

  const [enteredStatus, setStatus] = useState(
    value.preQual.clients[0].maritalStatus
  );

  value.preQual.clients[0].maritalStatus = enteredStatus;
  if (enteredStatus !== "") {
    value.setCompleted(true);
  }
  const clickHandler = (event) => {
    setStatus(event.target.value);
    value.preQual.clients[0].maritalStatus = event.target.value;
    if (event.target.value === "casado") {
      value.setNext(3, 4); // Sets back in case changed before.
      value.setCurrSlide(4);
    } else {
      value.setNext(3, 6); // If not married, skips asking for prenup data.
      value.setPrev(6, 3);
      value.setCurrSlide(6);
    }
  };

  return (
    <div className="marital-status-box">
      <Question
        questionText={value.locale === "en" ? "Are you:" : "Es usted:"}
        value={enteredStatus}
        buttons={value.locale === "en" ? enStatusButtons : statusButtons}
        clickHandler={clickHandler}
      />
    </div>
  );
};

export default MaritalStatus;
