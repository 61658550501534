import { useState, useContext } from "react";
import PreQContext from "../../PreQContext.js";
import Question from "../UI/Question.js";
const buttons = [
  { value: "true", text: "Si" },
  { value: "false", text: "No" },
];
const enButtons = [
  { value: "true", text: "Yes" },
  { value: "false", text: "No" },
];

const VAQuestion = (props) => {
  const value = useContext(PreQContext);
  if (value.preQual.VA !== undefined) {
    value.setCompleted(true);
  }
  const [enteredVA, setVA] = useState(String(value.preQual.VA));
  const clickHandler = (event) => {
    setVA(event.target.value === "true");
    value.preQual.VA = event.target.value === "true";
    value.setCurrSlide(value.slides[value.currSlide].next);
  };
  return (
    <div className="VA-box">
      <Question
        questionText={
          value.locale === "en"
            ? "Are you or any co-borrowers veterans?"
            : "¿Usted o algún co-deudor es veterano?"
        }
        value={enteredVA}
        buttons={value.locale === "en" ? enButtons : buttons}
        clickHandler={clickHandler}
      />
    </div>
  );
};

export default VAQuestion;
