import { useState } from "react";
import { createPortal } from "react-dom";
import Objects from "../../../Objects";
import validator from "validator";
const date = new Date();
let salaryValid = false,
  YOEValid = false,
  titleValid = false;
const shakeModal = () => {
  document.getElementsByClassName("modal")[0].style.animation =
    ".10s ease-out 0s 2 reverse running modalShakeInPlace";
  setTimeout(() => {
    document.getElementsByClassName("modal")[0].style.animation = "";
  }, 1000);
};
const JobInfoFormForm = (props) => {
  const [enteredSalary, setSalary] = useState("");
  const [enteredYOE, setEnteredYOE] = useState("");
  const [enteredType, setEnteredType] = useState("undefined");
  const [enteredFrequency, setEnteredFrequency] = useState("undefined");
  const [enteredTitle, setEnteredTitle] = useState("");
  const [pensionType, setPensionType] = useState("undefined");
  const [returns, setReturns] = useState({
    firstReturn: "",
    secondReturn: "",
    returnAverage: "",
  });
  const [hourly, setHourly] = useState({
    rate: "",
    hours: "",
    salary: "",
  });

  const hourlyRateChangeHandler = (event) => {
    if (
      validator.isNumeric(event.target.value.toString()) &&
      validator.isNumeric(hourly.hours.toString()) &&
      hourly.hours > 0
    ) {
      salaryValid = true;
      setHourly((prevState) => {
        return {
          ...prevState,
          rate: Number(event.target.value),
          salary: (Number(event.target.value) * hourly.hours * 52) / 12,
        };
      });
    } else {
      setHourly((prevState) => {
        return { ...prevState, rate: event.target.value };
      });
    }
  };
  const hoursWorkedChangeHandler = (event) => {
    if (
      validator.isNumeric(event.target.value.toString()) &&
      validator.isNumeric(hourly.rate.toString()) &&
      hourly.rate > 0
    ) {
      salaryValid = true;
      setHourly((prevState) => {
        return {
          ...prevState,
          hours: Number(event.target.value),
          salary: (Number(event.target.value) * hourly.rate * 52) / 12,
        };
      });
    } else {
      setHourly((prevState) => {
        return { ...prevState, hours: event.target.value };
      });
    }
  };
  const submitHandler = (event) => {
    event.preventDefault();
    if (enteredType === "Pension" && pensionType === "undefined") {
      shakeModal();
      document.getElementById("pensionType").style.backgroundColor = "red";
    } else if (
      titleValid &&
      YOEValid &&
      salaryValid &&
      enteredFrequency !== "undefined" &&
      enteredType !== "undefined"
    ) {
      let actualIncome = 0;
      if (enteredType === "Servicios Profesionales") {
        if (
          returns.firstReturn === 0 ||
          returns.secondReturn === 0 ||
          returns.firstReturn === "" ||
          returns.secondReturn === ""
        ) {
          shakeModal();
          if (!Number(returns.firstReturn) || returns.firstReturn === 0) {
            document.getElementById("firstReturn").style.backgroundColor =
              "red";
          }
          if (!Number(returns.firstReturn || returns.secondReturn === 0)) {
            document.getElementById("secondReturn").style.backgroundColor =
              "red";
          }
          return;
        }
        actualIncome = returns.returnAverage;
      } else if (enteredFrequency === "Por Hora") {
        if (
          hourly.hours === "" ||
          hourly.rate === "" ||
          !Number(hourly.hours) ||
          !Number(hourly.rate)
        ) {
          if (hourly.hours === 0 || hourly.hours === 0) {
            shakeModal();
            if (!Number(hourly.hours) || hourly.hours === 0) {
              document.getElementById("hours").style.backgroundColor = "red";
            }
          }
          if (hourly.rate === 0 || hourly.rate === 0) {
            shakeModal();
            if (!Number(hourly.hours) || hourly.hours === 0) {
              document.getElementById("rate").style.backgroundColor = "red";
            }
          }
        } else {
          actualIncome = hourly.salary;
        }
      } else {
        actualIncome = enteredSalary;
      }
      let newJob = new Objects.Job(
        enteredTitle,
        actualIncome,
        enteredYOE,
        enteredType,
        enteredFrequency,
        Math.random(),
        returns.firstReturn,
        returns.secondReturn,
        returns.returnAverage,
        pensionType,
        hourly.rate,
        hourly.hours
      );
      props.setJobs((prevState) => {
        return [newJob, ...prevState];
      });
      titleValid = false;
      YOEValid = false;
      salaryValid = false;
      props.setIsEditing(false);
    } else {
      console.log(YOEValid, salaryValid, titleValid);
      shakeModal();
      if (enteredType === "Pension" && pensionType === "undefined") {
        shakeModal();
        document.getElementById("pensionType").style.backgroundColor = "red";
      }
      if (enteredFrequency === "undefined") {
        document.getElementById("frequency").style.backgroundColor = "red";
      }
      if (enteredType === "undefined") {
        document.getElementById("type").style.backgroundColor = "red";
      }
      if (!salaryValid || enteredSalary === 0) {
        document.getElementById("salary").style.backgroundColor = "red";
      }
      if (!YOEValid || enteredYOE === 0) {
        document.getElementById("YOE").style.backgroundColor = "red";
      }
      if (!titleValid || enteredTitle === "") {
        document.getElementById("title").style.backgroundColor = "red";
      }
    }
  };

  const pensionChangeHandler = (event) => {
    setPensionType(event.target.value);
  };
  const firstReturnChangeHandler = (event) => {
    let newVal = Number(event.target.value);
    setReturns((prevState) => {
      let newAVG = (prevState.secondReturn + newVal) / 24;
      return { ...prevState, firstReturn: newVal, returnAverage: newAVG };
    });
  };
  const secondReturnChangeHandler = (event) => {
    let newVal = Number(event.target.value);
    setReturns((prevState) => {
      let newAVG = (prevState.firstReturn + newVal) / 24;
      return { ...prevState, secondReturn: newVal, returnAverage: newAVG };
    });
  };

  const salaryChangeHandler = (event) => {
    if (validator.isNumeric(event.target.value)) {
      salaryValid = true;
      setSalary(Number(event.target.value));
    } else {
      setSalary(event.target.value);
    }
  };
  const yoeChangeHandler = (event) => {
    YOEValid = validator.isNumeric(event.target.value);
    YOEValid
      ? setEnteredYOE(Number(event.target.value))
      : setEnteredYOE(event.target.value);
  };
  const typeChangeHandler = (event) => {
    setEnteredType(event.target.value);
    if (event.target.value !== "Nomina") {
      titleValid = true;
      YOEValid = true;
      setEnteredTitle(event.target.value);
    }
    if (event.target.value === "Servicios Profesionales") {
      salaryValid = true;
      setEnteredFrequency("Mensual");
    }
  };
  const frequencyChangeHandler = (event) => {
    setEnteredFrequency(event.target.value);
  };
  const titleChangeHandler = (event) => {
    setEnteredTitle(event.target.value);
    titleValid = validator.isAlphanumeric(event.target.value, "en-US", {
      ignore: " ",
    });
  };
  const cancelHandler = (event) => {
    event.preventDefault();
    titleValid = false;
    salaryValid = false;
    YOEValid = false;
    props.setIsEditing(false);
  };
  if (props.locale === "en") {
    return createPortal(
      <div className="overlay">
        <form className="modal" onSubmit={submitHandler}>
          <div className="form-box job">
            <p>
              Type of income:{" "}
              <select
                value={enteredType}
                style={{
                  backgroundColor: enteredType !== "undefined" ? "white" : "",
                }}
                id="type"
                onChange={typeChangeHandler}
              >
                <option value="undefined">--Select--</option>
                <option value="Nomina">Payroll</option>
                <option value="Pension">Pension</option>
                <option value="Seguro Social">Social Security</option>
                <option value="Servicios Profesionales">Self Employed</option>
              </select>
            </p>
            {enteredType === "Nomina" ? (
              <>
                <p>
                  Employer:{" "}
                  <input
                    placeholder="Nombre"
                    value={enteredTitle}
                    onChange={titleChangeHandler}
                    id="title"
                    type="text"
                    style={{
                      backgroundColor:
                        titleValid & (enteredTitle.length > 0) &&
                        "rgba(51, 58, 86, 1)",
                    }}
                  />
                </p>
              </>
            ) : (
              <></>
            )}

            {enteredType === "Nomina" && (
              <p>
                Years of Experience:
                <input
                  placeholder="Years"
                  value={enteredYOE}
                  label="años"
                  onChange={yoeChangeHandler}
                  id="YOE"
                  style={{
                    backgroundColor: !YOEValid
                      ? enteredYOE.length > 0 && "red"
                      : "",
                  }}
                />
              </p>
            )}
            {enteredType === "Servicios Profesionales" ? (
              <>
                <p>
                  Net income on {date.getFullYear() - 1} tax return:
                  <input
                    placeholder="000"
                    value={returns.firstReturn}
                    id="firstReturn"
                    onChange={firstReturnChangeHandler}
                    style={{
                      backgroundColor:
                        returns.firstReturn > 0 && "rgba(51, 58, 86, 1)",
                    }}
                  />
                </p>
                <p>
                  Net income on {date.getFullYear() - 2} tax return:
                  <input
                    placeholder="000"
                    value={returns.secondReturn}
                    id="secondReturn"
                    onChange={secondReturnChangeHandler}
                    style={{
                      backgroundColor:
                        returns.secondReturn > 0 && "rgba(51, 58, 86, 1)",
                    }}
                  />
                </p>
                <p>
                  Average monthly income:{" "}
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(returns.returnAverage)}
                </p>
              </>
            ) : (
              <p>
                {enteredFrequency !== "Por Hora" && (
                  <>
                    Salary:
                    <input
                      value={enteredSalary}
                      id="salary"
                      placeholder="000"
                      onChange={salaryChangeHandler}
                      style={{
                        backgroundColor: !salaryValid
                          ? enteredSalary.length > 0 && "red"
                          : "",
                      }}
                    />
                  </>
                )}
                <p>
                  {" "}
                  Payment frequency:{" "}
                  <select
                    value={enteredFrequency}
                    onChange={frequencyChangeHandler}
                    id="frequency"
                    style={{
                      backgroundColor:
                        enteredFrequency !== "undefined" ? "white" : "",
                    }}
                  >
                    <option value="undefined">--Select--</option>
                    <option value="Mensual">Monthly</option>
                    <option value="Quincenal">Semimonthly</option>
                    <option value="Semanal">Weekly</option>
                    <option value="Bi-Semanal">Bi-Weekly</option>
                    <option value="Anual">Annual</option>
                    <option value="Por Hora">Hourly</option>
                  </select>
                </p>
                {enteredFrequency === "Por Hora" && (
                  <>
                    <p>
                      Hourly Rate:
                      <input
                        id="rate"
                        value={hourly.rate}
                        onChange={hourlyRateChangeHandler}
                        style={{
                          backgroundColor:
                            Number(hourly.rate) &&
                            hourly.rate !== undefined &&
                            "rgba(51, 58, 86, 1)",
                        }}
                      />
                    </p>
                    <p>
                      Hours Worked:
                      <input
                        id="hours"
                        value={hourly.hours}
                        onChange={hoursWorkedChangeHandler}
                        style={{
                          backgroundColor:
                            Number(hourly.hours) &&
                            hourly.hours !== undefined &&
                            "rgba(51, 58, 86, 1)",
                        }}
                      />
                    </p>
                  </>
                )}
              </p>
            )}
            {enteredType === "Pension" ? (
              <>
                <p>
                  What kind of pension do you receive?
                  <select
                    id="pensionType"
                    value={pensionType}
                    style={{
                      backgroundColor: pensionType !== "undefined" && "",
                    }}
                    onChange={pensionChangeHandler}
                  >
                    <option value="undefined">--Select--</option>
                    <option value="Alimenticia">Alimony</option>
                    <option value="Seguro Social">Social Security</option>
                    <option value="Otra">Other</option>
                  </select>
                </p>
              </>
            ) : (
              <></>
            )}

            <button type="submit">Save</button>
            <button onClick={cancelHandler}>Cancel</button>
            {enteredType === "Servicios Profesionales" && (
              <p className="bottom-warning">
                In order to consider self-employment income, you must have filed
                two tax returns.
              </p>
            )}
            {enteredType === "Pension" ? (
              pensionType === "Alimenticia" ? (
                <p className="bottom-warning">
                  Alimony income must have been received to a bank account,
                  without interruption, for 12 months.
                </p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
        </form>
      </div>,
      document.body
    );
  } else {
    return createPortal(
      <div className="overlay">
        <form className="modal" onSubmit={submitHandler}>
          <div className="form-box job">
            <p>
              Tipo de ingreso:{" "}
              <select
                value={enteredType}
                style={{
                  backgroundColor: enteredType !== "undefined" ? "white" : "",
                }}
                id="type"
                onChange={typeChangeHandler}
              >
                <option value="undefined">--Seleccione--</option>
                <option value="Nomina">Nomina</option>
                <option value="Pension">Pension</option>
                <option value="Seguro Social">Seguro Social</option>
                <option value="Servicios Profesionales">
                  Servicios Profesionales
                </option>
              </select>
            </p>
            {enteredType === "Nomina" ? (
              <>
                <p>
                  Patrono:{" "}
                  <input
                    placeholder="Nombre"
                    value={enteredTitle}
                    onChange={titleChangeHandler}
                    id="title"
                    type="text"
                    style={{
                      backgroundColor: !titleValid
                        ? enteredTitle.length > 0 && "red"
                        : "",
                    }}
                  />
                </p>
              </>
            ) : (
              <></>
            )}

            {enteredType === "Nomina" && (
              <p>
                Años en linea empleo:
                <input
                  placeholder="Años"
                  value={enteredYOE}
                  label="años"
                  onChange={yoeChangeHandler}
                  id="YOE"
                  style={{
                    backgroundColor: !YOEValid
                      ? enteredYOE.length > 0 && "red"
                      : "",
                  }}
                />
              </p>
            )}
            {enteredType === "Servicios Profesionales" ? (
              <>
                <p>
                  Ganancia rendida en planilla {date.getFullYear() - 1}:
                  <input
                    placeholder="000"
                    value={returns.firstReturn}
                    id="firstReturn"
                    onChange={firstReturnChangeHandler}
                    style={{
                      backgroundColor:
                        returns.firstReturn > 0 && "rgba(51, 58, 86, 1)",
                    }}
                  />
                </p>
                <p>
                  Ganancia rendida en planilla {date.getFullYear() - 2}:
                  <input
                    placeholder="000"
                    value={returns.secondReturn}
                    id="secondReturn"
                    onChange={secondReturnChangeHandler}
                    style={{
                      backgroundColor:
                        returns.secondReturn > 0 && "rgba(51, 58, 86, 1)",
                    }}
                  />
                </p>
                <p>
                  Salario promedio:{" "}
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(returns.returnAverage)}
                </p>
              </>
            ) : (
              <p>
                {enteredFrequency !== "Por Hora" && (
                  <>
                    Salario:
                    <input
                      value={enteredSalary}
                      id="salary"
                      placeholder="000"
                      onChange={salaryChangeHandler}
                      style={{
                        backgroundColor: !salaryValid
                          ? enteredSalary.length > 0 && "red"
                          : "",
                      }}
                    />
                  </>
                )}
                <p>
                  {" "}
                  Frequencia de pago:{" "}
                  <select
                    value={enteredFrequency}
                    onChange={frequencyChangeHandler}
                    id="frequency"
                    style={{
                      backgroundColor:
                        enteredFrequency !== "undefined" ? "white" : "",
                    }}
                  >
                    <option value="undefined">--Seleccione--</option>
                    <option value="Mensual">Mensual</option>
                    <option value="Quincenal">Quincenal</option>
                    <option value="Semanal">Semanal</option>
                    <option value="Bi-Semanal">Bi-Semanal</option>
                    <option value="Anual">Anual</option>
                    <option value="Por Hora">Por Hora</option>
                  </select>
                </p>
                {enteredFrequency === "Por Hora" && (
                  <>
                    <p>
                      Pago Por Hora:
                      <input
                        id="rate"
                        value={hourly.rate}
                        onChange={hourlyRateChangeHandler}
                        style={{
                          backgroundColor:
                            Number(hourly.rate) &&
                            hourly.rate !== undefined &&
                            "rgba(51, 58, 86, 1)",
                        }}
                      />
                    </p>
                    <p>
                      Horas Trabajadas:
                      <input
                        id="hours"
                        value={hourly.hours}
                        onChange={hoursWorkedChangeHandler}
                        style={{
                          backgroundColor:
                            Number(hourly.hours) &&
                            hourly.hours !== undefined &&
                            "rgba(51, 58, 86, 1)",
                        }}
                      />
                    </p>
                  </>
                )}
              </p>
            )}
            {enteredType === "Pension" ? (
              <>
                <p>
                  Que tipo de pension recibe?
                  <select
                    id="pensionType"
                    value={pensionType}
                    style={{
                      backgroundColor: pensionType !== "undefined" && "",
                    }}
                    onChange={pensionChangeHandler}
                  >
                    <option value="undefined">--Seleccione--</option>
                    <option value="Alimenticia">Alimenticia</option>
                    <option value="Seguro Social">Seguro Social</option>
                    <option value="Otra">Otra</option>
                  </select>
                </p>
              </>
            ) : (
              <></>
            )}

            <button type="submit">Guardar</button>
            <button onClick={cancelHandler}>Cancelar</button>
            {enteredType === "Servicios Profesionales" && (
              <p className="bottom-warning">
                Para poder considerar ingresos de servicios profesionales debe
                haber rendido 2 planillas.
              </p>
            )}
            {enteredType === "Pension" ? (
              pensionType === "Alimenticia" ? (
                <p className="bottom-warning">
                  El ingreso de pension alimenticia debe haberse recibido sin
                  interrupcion por los ultimos 12 meses a una cuenta bancaria.
                </p>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>
        </form>
      </div>,
      document.body
    );
  }
};

export default JobInfoFormForm;
