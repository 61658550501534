import Objects from "../../../Objects";
import { useState } from "react";
import "./ClientInfoFormForm.css";
import { createPortal } from "react-dom";
import validator from "validator";
const validateEmail = (email) => {
  return validator.isEmail(email);
};
const validateTel = (tel) => {
  return validator.isMobilePhone(tel, "en-US");
};
const validateText = (text) => {
  return validator.isAlpha(text, "es-ES", { ignore: " " });
};

const ClientInfoFormForm = (props) => {
  const [enteredName, setName] = useState("");
  const [enteredSurname, setSurname] = useState("");
  const [enteredEmail, setEmail] = useState("");
  const [enteredPhone, setPhone] = useState("");
  const [phoneValid, setPhoneValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [nameValid, setNameValid] = useState(false);
  const [surnameValid, setSurnameValid] = useState(false);

  const validateForm = () => {
    //change to use effect w/ debouncing and reducer
    if (
      enteredName.length > 0 &&
      enteredSurname.length > 0 &&
      enteredEmail.length > 0 &&
      enteredPhone.length > 0 &&
      phoneValid &&
      emailValid &&
      surnameValid &&
      nameValid
    ) {
      return true;
    } else {
      return false;
    }
  };

  const nameChangeHandler = (event) => {
    setName(event.target.value);
    validateText(event.target.value.trim())
      ? setNameValid(true)
      : setNameValid(false);
  };
  const surnameChangeHandler = (event) => {
    setSurname(event.target.value);
    validateText(event.target.value.trim())
      ? setSurnameValid(true)
      : setSurnameValid(false);
  };
  const emailChangeHandler = (event) => {
    setEmail(event.target.value);
    validateEmail(event.target.value.trim())
      ? setEmailValid(true)
      : setEmailValid(false);
  };
  const phoneChangeHandler = (event) => {
    setPhone(event.target.value);
    validateTel(event.target.value)
      ? setPhoneValid(true)
      : setPhoneValid(false);
  };
  const submitHandler = (event) => {
    event.preventDefault();

    if (validateForm()) {
      console.log("form valid");
      let newClient = new Objects.Client(
        enteredName,
        enteredSurname,
        enteredPhone,
        enteredEmail,
        Math.random()
      );
      props.setClients((prevState) => {
        return [...prevState, newClient];
      });
      setName("");
      setSurname("");
      setEmail("");
      setPhone("");
      props.setIsEditing(false);
    } else {
      document.getElementsByClassName("modal")[0].style.animation =
        ".10s ease-out 0s 2 reverse running modalShakeInPlace";
      setTimeout(() => {
        document.getElementsByClassName("modal")[0].style.animation = "";
      }, 1000);
      if (!phoneValid) {
        document.getElementById("tel").style.backgroundColor = "red";
      }
      if (!emailValid) {
        document.getElementById("email").style.backgroundColor = "red";
      }
      if (!surnameValid) {
        document.getElementById("lastName").style.backgroundColor = "red";
      }
      if (!nameValid) {
        document.getElementById("name").style.backgroundColor = "red";
      }
    }
  };
  const cancelHandler = (event) => {
    event.preventDefault();
    setName("");
    setSurname("");
    setEmail("");
    setPhone("");
    props.setIsEditing(false);
  };

  if (props.locale === "en") {
    return createPortal(
      <div className="overlay">
        <form className="modal" onSubmit={submitHandler}>
          <div className="form-box">
            <input
              value={enteredName}
              maxLength="50"
              placeholder="Name"
              id="name"
              type="text"
              onChange={nameChangeHandler}
              style={{
                backgroundColor: !nameValid
                  ? enteredName.length > 0
                    ? "red"
                    : ""
                  : " rgba(51, 58, 86, 1)",
                color: !nameValid
                  ? "rgba(247, 245, 230, 1)"
                  : "rgba(247, 245, 230, 1)",
              }}
            />
            <input
              value={enteredSurname}
              maxLength="50"
              placeholder="Surname"
              id="lastName"
              type="text"
              onChange={surnameChangeHandler}
              style={{
                backgroundColor: !surnameValid
                  ? enteredSurname.length > 0
                    ? "red"
                    : ""
                  : " rgba(51, 58, 86, 1)",
                color: !surnameValid
                  ? "rgba(247, 245, 230, 1)"
                  : "rgba(247, 245, 230, 1)",
              }}
            />
            <input
              value={enteredEmail}
              maxLength="50"
              placeholder="email@email.com"
              type="email"
              id="email"
              onChange={emailChangeHandler}
              style={{
                backgroundColor: !emailValid
                  ? enteredEmail.length > 0
                    ? "red"
                    : ""
                  : " rgba(51, 58, 86, 1)",
                color: !emailValid
                  ? "rgba(247, 245, 230, 1)"
                  : "rgba(247, 245, 230, 1)",
              }}
            />
            <input
              value={enteredPhone}
              placeholder="787-xxx-xxxx"
              type="tel-national"
              id="tel"
              onChange={phoneChangeHandler}
              style={{
                backgroundColor: !phoneValid
                  ? enteredPhone.length > 0
                    ? "red"
                    : ""
                  : " rgba(51, 58, 86, 1)",
                color: !phoneValid
                  ? "rgba(247, 245, 230, 1)"
                  : "rgba(247, 245, 230, 1)",
              }}
            />
            <button type="submit">Save</button>
            {props.preQual.clients.length > 0 ? (
              <button onClick={cancelHandler}>Cancel</button>
            ) : (
              <></>
            )}
          </div>
        </form>
      </div>,
      document.body
    );
  } else {
    return createPortal(
      <div className="overlay">
        <form className="modal" onSubmit={submitHandler}>
          <div className="form-box">
            <input
              value={enteredName}
              maxLength="50"
              placeholder="Nombre"
              id="name"
              type="text"
              onChange={nameChangeHandler}
              style={{
                backgroundColor: !nameValid
                  ? enteredName.length > 0
                    ? "red"
                    : ""
                  : " rgba(51, 58, 86, 1)",
                color: !nameValid
                  ? "rgba(247, 245, 230, 1)"
                  : "rgba(247, 245, 230, 1)",
              }}
            />
            <input
              value={enteredSurname}
              maxLength="50"
              placeholder="Apellido"
              id="lastName"
              type="text"
              onChange={surnameChangeHandler}
              style={{
                backgroundColor: !surnameValid
                  ? enteredSurname.length > 0
                    ? "red"
                    : ""
                  : " rgba(51, 58, 86, 1)",
                color: !surnameValid
                  ? "rgba(247, 245, 230, 1)"
                  : "rgba(247, 245, 230, 1)",
              }}
            />
            <input
              value={enteredEmail}
              maxLength="50"
              placeholder="correo@email.com"
              type="email"
              id="email"
              onChange={emailChangeHandler}
              style={{
                backgroundColor: !emailValid
                  ? enteredEmail.length > 0
                    ? "red"
                    : ""
                  : " rgba(51, 58, 86, 1)",
                color: !emailValid
                  ? "rgba(247, 245, 230, 1)"
                  : "rgba(247, 245, 230, 1)",
              }}
            />
            <input
              value={enteredPhone}
              placeholder="787-xxx-xxxx"
              type="tel-national"
              id="tel"
              onChange={phoneChangeHandler}
              style={{
                backgroundColor: !phoneValid
                  ? enteredPhone.length > 0
                    ? "red"
                    : ""
                  : " rgba(51, 58, 86, 1)",
                color: !phoneValid
                  ? "rgba(247, 245, 230, 1)"
                  : "rgba(247, 245, 230, 1)",
              }}
            />
            <button type="submit">Guardar</button>
            {props.preQual.clients.length > 0 ? (
              <button onClick={cancelHandler}>Cancelar</button>
            ) : (
              <></>
            )}
          </div>
        </form>
      </div>,
      document.body
    );
  }
};

export default ClientInfoFormForm;
