import { useState } from "react";
import { createPortal } from "react-dom";
import Objects from "../../../Objects";
import "./ClientInfoFormForm.css";
import validator from "validator";
const validateEmail = (email) => {
  return validator.isEmail(email);
};
const validateTel = (tel) => {
  return validator.isMobilePhone(tel, "en-US");
};
const validateText = (text) => {
  return validator.isAlpha(text, "es-ES", { ignore: " " });
};
const ClientEditForm = (props) => {
  const [enteredName, setName] = useState(props.client.firstName);
  const [enteredSurname, setSurname] = useState(props.client.lastName);
  const [enteredEmail, setEmail] = useState(props.client.email);
  const [enteredPhone, setPhone] = useState(props.client.phoneNumber);
  const [phoneValid, setPhoneValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [nameValid, setNameValid] = useState(true);
  const [surnameValid, setSurnameValid] = useState(true);
  const validateForm = () => {
    if (
      enteredName.length > 0 &&
      enteredSurname.length > 0 &&
      enteredEmail.length > 0 &&
      enteredPhone.length > 0 &&
      phoneValid &&
      emailValid &&
      surnameValid &&
      nameValid
    ) {
      return true;
    } else {
      console.log("invalid form");
      return false;
    }
  };
  const nameChangeHandler = (event) => {
    setName(event.target.value);
    validateText(event.target.value.trim())
      ? setNameValid(true)
      : setNameValid(false);
  };
  const surnameChangeHandler = (event) => {
    setSurname(event.target.value);
    validateText(event.target.value.trim())
      ? setSurnameValid(true)
      : setSurnameValid(false);
  };
  const emailChangeHandler = (event) => {
    setEmail(event.target.value);
    validateEmail(event.target.value.trim())
      ? setEmailValid(true)
      : setEmailValid(false);
  };
  const phoneChangeHandler = (event) => {
    setPhone(event.target.value);
    validateTel(event.target.value)
      ? setPhoneValid(true)
      : setPhoneValid(false);
  };
  const submitHandler = (event) => {
    event.preventDefault();
    if (validateForm) {
      let newClient = new Objects.Client(
        enteredName,
        enteredSurname,
        enteredPhone,
        enteredEmail,
        props.client.id,
        props.client.debts,
        props.client.jobs,
        props.client.income,
        props.client.debt,
        props.client.privacy,
        props.client.creditCheck,
        props.client.creditScore,
        props.client.maritalStatus,
        props.client.preNup,
        props.client.noDebt,
        props.client.noMoreDebt,
        props.client.noIncome
      );
      props.setClients((clients) =>
        clients.filter((client) => client.id !== props.client.id)
      );
      props.setClients((prevState) => {
        return [newClient, ...prevState];
      });
      setName("");
      setSurname("");
      setEmail("");
      setPhone("");
      props.setIsEditing(false);
    } else {
      console.log("Invalid Form");
      document.getElementsByClassName("modal")[0].style.animation =
        ".10s ease-out 0s 2 reverse running modalShakeInPlace";
      setTimeout(() => {
        document.getElementsByClassName("modal")[0].style.animation = "";
      }, 1000);
      if (!phoneValid) {
        document.getElementById("tel").style.backgroundColor = "red";
      }
      if (!emailValid) {
        document.getElementById("email").style.backgroundColor = "red";
      }
      if (!surnameValid) {
        document.getElementById("lastName").style.backgroundColor = "red";
      }
      if (!nameValid) {
        document.getElementById("name").style.backgroundColor = "red";
      }
    }
  };
  const cancelHandler = (event) => {
    event.preventDefault();
    setName("");
    setSurname("");
    setEmail("");
    setPhone("");
    props.setIsEditing(false);
  };
  if (props.locale === "en") {
    return createPortal(
      <div className="overlay">
        <form className="modal" onSubmit={submitHandler}>
          <div className="form-box">
            <input
              value={enteredName}
              maxLength="50"
              placeholder="Name"
              id="name"
              type="text"
              onChange={nameChangeHandler}
              style={{
                backgroundColor: !nameValid
                  ? "red"
                  : enteredName.length > 0
                  ? " rgba(51, 58, 86, 1)"
                  : "",
                color: !nameValid
                  ? "rgba(247, 245, 230, 1)"
                  : "rgba(247, 245, 230, 1)",
              }}
            />
            <input
              value={enteredSurname}
              maxLength="50"
              placeholder="Surname"
              id="lastName"
              type="text"
              onChange={surnameChangeHandler}
              style={{
                backgroundColor: !surnameValid
                  ? "red"
                  : enteredSurname.length > 0
                  ? " rgba(51, 58, 86, 1)"
                  : "",
                color: !surnameValid
                  ? "rgba(247, 245, 230, 1)"
                  : "rgba(247, 245, 230, 1)",
              }}
            />
            <input
              value={enteredEmail}
              maxLength="50"
              placeholder="email@email.com"
              type="email"
              id="email"
              onChange={emailChangeHandler}
              style={{
                backgroundColor: !emailValid
                  ? "red"
                  : enteredEmail.length > 0
                  ? " rgba(51, 58, 86, 1)"
                  : "",
                color: !emailValid
                  ? "rgba(247, 245, 230, 1)"
                  : "rgba(247, 245, 230, 1)",
              }}
            />
            <input
              value={enteredPhone}
              maxLength="18"
              placeholder="787-xxx-xxxx"
              type="tel"
              id="tel"
              onChange={phoneChangeHandler}
              style={{
                backgroundColor: !phoneValid
                  ? "red"
                  : enteredPhone.length > 0
                  ? " rgba(51, 58, 86, 1)"
                  : "",
                color: !phoneValid
                  ? "rgba(247, 245, 230, 1)"
                  : "rgba(247, 245, 230, 1)",
              }}
            />
            {validateForm() ? <button type="submit">Save Changes</button> : ""}
            <button onClick={cancelHandler}>Cancel</button>
          </div>
        </form>
      </div>,
      document.body
    );
  } else {
    return createPortal(
      <div className="overlay">
        <form className="modal" onSubmit={submitHandler}>
          <div className="form-box">
            <input
              value={enteredName}
              maxLength="50"
              placeholder="Nombre"
              id="name"
              type="text"
              onChange={nameChangeHandler}
              style={{
                backgroundColor: !nameValid
                  ? "red"
                  : enteredName.length > 0
                  ? " rgba(51, 58, 86, 1)"
                  : "",
                color: !nameValid
                  ? "rgba(247, 245, 230, 1)"
                  : "rgba(247, 245, 230, 1)",
              }}
            />
            <input
              value={enteredSurname}
              maxLength="50"
              placeholder="Apellido"
              id="lastName"
              type="text"
              onChange={surnameChangeHandler}
              style={{
                backgroundColor: !surnameValid
                  ? "red"
                  : enteredSurname.length > 0
                  ? " rgba(51, 58, 86, 1)"
                  : "",
                color: !surnameValid
                  ? "rgba(247, 245, 230, 1)"
                  : "rgba(247, 245, 230, 1)",
              }}
            />
            <input
              value={enteredEmail}
              maxLength="50"
              placeholder="correo@email.com"
              type="email"
              id="email"
              onChange={emailChangeHandler}
              style={{
                backgroundColor: !emailValid
                  ? "red"
                  : enteredEmail.length > 0
                  ? " rgba(51, 58, 86, 1)"
                  : "",
                color: !emailValid
                  ? "rgba(247, 245, 230, 1)"
                  : "rgba(247, 245, 230, 1)",
              }}
            />
            <input
              value={enteredPhone}
              maxLength="12"
              placeholder="787-xxx-xxxx"
              type="tel"
              id="tel"
              onChange={phoneChangeHandler}
              style={{
                backgroundColor: !phoneValid
                  ? "red"
                  : enteredPhone.length > 0
                  ? " rgba(51, 58, 86, 1)"
                  : "",
                color: !phoneValid
                  ? "rgba(247, 245, 230, 1)"
                  : "rgba(247, 245, 230, 1)",
              }}
            />
            {validateForm() ? (
              <button type="submit">Guardar Cambios</button>
            ) : (
              ""
            )}
            <button onClick={cancelHandler}>Cancelar</button>
          </div>
        </form>
      </div>,
      document.body
    );
  }
};

export default ClientEditForm;
