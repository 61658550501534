import { useContext } from "react";
import PreQContext from "../../PreQContext";
import "./MoveButtons.css";
import chevron from "../../images/chevron-right.png";

const PrevButton = (props) => {
  const value = useContext(PreQContext);
  const prevClickHandler = () => {
    value.setCurrSlide(value.slides[value.currSlide].prev);
  };
  return (
    <>
      <div
        className={props.classOne}
        style={{ opacity: value.currSlide > 0 ? "100" : "0" }}
        onClick={prevClickHandler}
      >
        <div className={props.classTwo}> {props.title} </div>
        <img
          className="chevron-prev"
          src={chevron}
          alt="chevron pointing left"
        />
      </div>
    </>
  );
};

export default PrevButton;
