import { useContext } from "react";
import PreQContext from "../../PreQContext";

const WillCall = (props) => {
  const value = useContext(PreQContext);
  const { maxFHALAMT, originator } = value.preQual;
  if (value.locale === "en") {
    return (
      <div className="disclaimer-box">
        <p className="question-text">¡¡Thank you for your submission!!</p>
        <br />
        <p className="will-call">
          We are pleased to inform you that one of our representatives,{" "}
          {originator === "660" ? "Edward Cruz" : "Rafael Cruz"}, will contact
          you shortly to discuss the financing options available and the amount
          for which you could be prequalified for a loan.
        </p>
        <p className="will-call">
          {" "}
          We look forward to working with you to help you achieve your financial
          goals. Mr. Cruz's number is{" "}
          {originator === "660" ? "787-983-1103" : "787-237-6300"}.
        </p>
        <p className="bottom-disclaimer">
          This is not a pre-qualification letter. If possible, Mr. Cruz will
          provide you with such a letter after his call.
        </p>
      </div>
    );
  } else {
    return (
      <div className="disclaimer-box">
        <p className="question-text">¡¡Gracias por completar!!</p>
        <br />
        <p className="will-call">
          Nos complace informarle que uno de nuestros representantes,{" "}
          {originator === "660" ? "Edward Cruz" : "Rafael Cruz"}, se comunicará
          con usted en breve para discutir las opciones de financiamiento
          disponibles y la cantidad para la cual podría ser precalificado para
          un préstamo.{" "}
        </p>
        <p className="will-call">
          {" "}
          Esperamos trabajar con usted para ayudarle a alcanzar sus metas
          financieras. El numero del Sr. Cruz es{" "}
          {originator === "660" ? "787-983-1103" : "787-237-6300"}.
        </p>
        <p className="bottom-disclaimer">
          Esto no es una carta de precalificación. Si es posible, el Sr. Cruz le
          proporcionará dicha carta después de su llamada.
        </p>
      </div>
    );
  }
};
export default WillCall;
