import { useState, useContext } from "react";
import ClientButton from "./ClientButton";
import ClientInfoFormForm from "./ClientInfoFormForm";
import Card from "../../UI/Card";
import "./ClientInfoForm.css";
import PreQContext from "../../../PreQContext";

const ClientInfoForm = (props) => {
  const value = useContext(PreQContext);
  const [isEditing, setIsEditing] = useState(false);
  const clickHandler = () => {
    setIsEditing(true);
  };
  const [clients, setClients] = useState(value.preQual.clients);
  value.preQual.clients = clients;
  if (clients.length > 0) {
    value.setCompleted(true);
  } else {
    value.setCompleted(false);
  }
  if (clients.length === 0) {
    return (
      <>
        <ClientInfoFormForm
          locale={value.locale}
          clients={clients}
          preQual={value.preQual}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          setClients={setClients}
        />
      </>
    );
  } else {
    if (value.locale === "en") {
      return (
        <div className="client-button-box">
          <p className="question-text">
            {" "}
            Would you like to add a co-borrower?{" "}
          </p>
          <button className="add-client-button" onClick={clickHandler}>
            Add Co-Borrower
          </button>
          {clients.map((client) => (
            <Card key={client.id}>
              <ClientButton
                locale={value.locale}
                client={client}
                key={client.id}
                clients={clients}
                setClients={setClients}
              />
              <br />
            </Card>
          ))}

          {isEditing ? (
            <ClientInfoFormForm
              preQual={value.preQual}
              locale={value.locale}
              clients={clients}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              setClients={setClients}
            />
          ) : (
            <></>
          )}
        </div>
      );
    } else {
      return (
        <div className="client-button-box">
          <p className="question-text"> ¿Desea añadir co-deudor? </p>
          <button className="add-client-button" onClick={clickHandler}>
            Añadir co-deudor
          </button>
          {clients.map((client) => (
            <Card key={client.id}>
              <ClientButton
                locale={value.locale}
                client={client}
                key={client.id}
                clients={clients}
                setClients={setClients}
              />
              <br />
            </Card>
          ))}

          {isEditing ? (
            <ClientInfoFormForm
              locale={value.locale}
              preQual={value.preQual}
              clients={clients}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              setClients={setClients}
            />
          ) : (
            <></>
          )}
        </div>
      );
    }
  }
};
export default ClientInfoForm;
