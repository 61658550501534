import JobInfoForm from "./JobInfo/JobInfoForm";
import { useContext } from "react";
import PreQContext from "../../PreQContext";
import Card from "../UI/Card";

const JobInfoPage = (props) => {
  const value = useContext(PreQContext);
  return (
    <div className="client-button-box">
      {value.preQual.clients.map((client) => (
        <Card>
          <JobInfoForm
            locale={value.locale}
            preQual={value.preQual}
            setCompleted={value.setCompleted}
            client={client}
          />
        </Card>
      ))}
    </div>
  );
};

export default JobInfoPage;
