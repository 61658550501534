import React, { useContext, useState } from "react";
import PreQContext from "../../PreQContext";

const Landing = (props) => {
  const value = useContext(PreQContext);
  var policy = false;
  const [policyModal,setPolicyModal] = useState(undefined)
  const [terms, setTerms] = useState(value.preQual.terms);
  const [privacy, setPrivacy] = useState(value.preQual.privacy);
  const termsHandler = (event) => {
    setTerms(!terms);
    value.preQual.terms = !terms;
  };
  const privacyHandler = (event) => {
    setPrivacy(!privacy);
    value.preQual.privacy = !privacy;
  };
  const showPolicyHandler = () => {
    console.log("clicked", policy, typeof(policy), policy === false);
    if (policy === false){
      policy = true;
      setPolicyModal(
      <>
        
        <div className="overlay" id="privacyPolicyDiv">
        <button className="policy-exit-button" onClick={showPolicyHandler}>
          Close Policy Display / Cerrar Politica de Privacidad
        </button>
          <embed
            src = "https://drive.google.com/file/d/1kkytXs_IgK7jpkbyZLl7ce_rBF9dntdn/preview"
            type="application/pdf"
            width="100%"
            style={{ height: 'calc(101vh - 43px)' }}
          />
        </div>
      </>
      );
    } else {
      policy = false;
      setPolicyModal(undefined)
    }
  }
  if (privacy && terms) {
    value.setCompleted(true);
  } else {
    value.setCompleted(false);
  }
  if (value.locale === "en") {
    return (
      <>
      {policyModal}
      <div className="disclaimer-box">
        <p>
          We will be asking you some questions to pre-qualify you for a mortgage
          loan.
        </p>
        <br />
        <p>
          Pre-qualification is not a loan offer, nor does it guarantee loan
          approval. It is prepared based on the information you provide to us,
          which will be received in good faith. At the time of initiating the
          mortgage loan, the information provided will need to be supported by
          relevant documentation and a credit report.
        </p>
        <br />
        <div className="disclaimer-checkboxes">
          <p className="disclaimer-checkbox-container" onClick={termsHandler}>
            <input
              type="checkbox"
              style={{
                outline: terms ? "" : "red solid 1.5px",
              }}
              checked={terms && "checked"}
              onChange={termsHandler}
            />
            I have read and am in agreement with the information provided above.
          </p>
          <p className="disclaimer-checkbox-container" >
            <input
              type="checkbox"
              style={{
                outline: privacy ? "" : "red solid 1.5px",
              }}
              checked={privacy && "checked"}
              onChange={privacyHandler}
              onClick={privacyHandler}
            />
            I have read and am in agreement with the <a href="https://drive.google.com/uc?export=download&id=1kkytXs_IgK7jpkbyZLl7ce_rBF9dntdn">privacy policy (click to view).</a>
          </p>
        </div>
      </div>
      </>
    );
  } else {
    return (
      <>
      {policyModal}
      <div className="disclaimer-box">
        <p>
          Estaremos realizando una serie de preguntas con el fin de
          pre-calificarle para un préstamo hipotecario.
        </p>
        <br />
        <p>
          La precalificación no es una oferta de préstamo, ni garatantiza la
          aprobación de un préstamo. La misma se prepará en base a la
          información que usted nos provea y ésta será recibida de buena fe. Al
          momento de iniciar el préstamo hipotecario, se tendrá que respaldar la
          información provista con la documentación pertinente.
        </p>
        <div className="disclaimer-checkboxes">
          <p className="disclaimer-checkbox-container" onClick={termsHandler}>
            <input
              type="checkbox"
              style={{
                outline: terms ? "" : "red solid 1.5px",
              }}
              checked={terms && "checked"}
              onChange={termsHandler}
            />
            He leído y estoy de acuerdo con la información aquí provista.
          </p>
          <p className="disclaimer-checkbox-container" onClick={privacyHandler}>
            <input
              type="checkbox"
              style={{
                outline: privacy ? "" : "red solid 1.5px",
              }}
              checked={privacy && "checked"}
              onChange={privacyHandler}
            />
            He leído y estoy de acuerdo con la <a href="https://drive.google.com/uc?export=download&id=1kkytXs_IgK7jpkbyZLl7ce_rBF9dntdn">politica de privacidad (presione para ver).</a>
          </p>
        </div>
      </div>
      
      </>
    );
  }
};

export default Landing;
