// Set interest rates and other static globals here.
const INTERESTRATE = 7.75,
  FHALOANLIMIT = 470000,
  RHSINCOMELIMIT = 103500;

// From stackoverflow, generates ID values.
function makeid(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

//Returns monthly payment
const pmt_calculator = (loanamount, term, rate) => {
  var irate = rate / 100,
    months = term * 12,
    monthlyirate = irate / 12,
    payment =
      loanamount *
      ((monthlyirate * (1 + monthlyirate) ** months) /
        ((1 + monthlyirate) ** months - 1));
  return payment;
};

class preQual {
  constructor(
    originator = "660",
    clients = [],
    property = {},
    purpose = "",
    moreClients = undefined,
    VA = undefined,
    totalDebt = 0,
    totalIncome = 0,
    maxFHAPITI = 0,
    maxRHSPITI = 0,
    maxFHALAMT = 0,
    maxRHSLAMT = 0,
    privacy = false,
    terms = false,
    id = makeid(10),
    hidden = false,
    favorite = false
  ) {
    this.originator = originator;
    this.clients = clients;
    this.property = property;
    this.purpose = purpose;
    this.moreClients = moreClients;
    this.VA = VA;
    this.totalDebt = totalDebt;
    this.totalIncome = totalIncome;
    this.maxFHAPITI = maxFHAPITI;
    this.maxRHSPITI = maxRHSPITI;
    this.maxFHALAMT = maxFHALAMT;
    this.maxRHSLAMT = maxRHSLAMT;
    this.privacy = privacy;
    this.terms = terms;
    this.id = id;
    this.hidden = hidden;

    //This calculates the qualification when called.
    this.prequalify = function () {
      this.totalDebt = 0;
      this.totalIncome = 0;
      this.maxFHALAMT = 0;
      this.maxRHSLAMT = 0;
      const clients = this.clients.values();

      for (let client of clients) {
        const jobs = client.jobs.values();
        const debts = client.debts.values();
        client.income = 0;
        client.debt = 0;
        for (let debt of debts) {
          if (debt.type !== "Student Loan") {
            client.debt += debt.payment;
          } else {
            client.debt += debt.balance * 0.005;
          }
        }
        for (let job of jobs) {
          if (job.type === "Servicios Profesionales") {
            //In case of self-employed clients, income is calculated based on average from returns.
            client.income += job.returnAverage;
          } else if (job.type === "Seguro Social" || job.type === "Pension") {
            client.income += job.salary * 1.1; // increase untaxed income by 10%
          } else {
            switch (job.paymentFrequency) {
              case "Mensual":
                client.income += job.salary;
                break;
              case "Semanal":
                client.income += (job.salary * 52) / 12;
                break;
              case "Bi-Semanal":
                client.income += (job.salary * 26) / 12;
                break;
              case "Quincenal":
                client.income += job.salary * 2;
                break;
              case "Anual":
                client.income += job.salary / 12;
                break;
              default:
                client.income += job.salary;
                break;
            }
          }
        }
        this.totalDebt += client.debt;
        this.totalIncome += client.income;
      }
      if (this.totalIncome * 0.47 - this.totalDebt < this.totalIncome * 0.37) {
        this.maxFHAPITI = this.totalIncome * 0.47 - this.totalDebt;
      } else {
        this.maxFHAPITI = this.totalIncome * 0.37;
      }
      if (this.totalIncome * 0.41 - this.totalDebt < this.totalIncome * 0.31) {
        this.maxRHSPITI = this.totalIncome * 0.41 - this.totalDebt;
      } else {
        this.maxRHSPITI = this.totalIncome * 0.31;
      }
      if (this.totalIncome * 12 < RHSINCOMELIMIT) {
        while (
          pmt_calculator(this.maxRHSLAMT, 30, INTERESTRATE) +
            (this.maxRHSLAMT * 0.0035) / 12 +
            (this.maxRHSLAMT * 0.0035) / 12 <
          this.maxRHSPITI
        ) {
          console.log("Calculating rhs");
          this.maxRHSLAMT += 5000;
        }
      }
      while (
        this.maxFHALAMT < FHALOANLIMIT &&
        pmt_calculator(this.maxFHALAMT * 0.965, 30, INTERESTRATE) +
          (this.maxFHALAMT * 0.0035) / 12 +
          (this.maxFHALAMT * 0.0085) / 12 <
          this.maxFHAPITI
      ) {
        console.log("Calculating fha");
        this.maxFHALAMT += 5000;
      }
    };
  }
}

class Client {
  constructor(
    firstName = "",
    lastName = "",
    phoneNumber = "",
    email = "",
    id = 0,
    debts = [],
    jobs = [],
    income = 0,
    debt = 0,
    privacy = false,
    creditCheck = false,
    creditScore,
    maritalStatus = "",
    preNup = null,
    noDebt = false,
    noMoreDebt = false,
    noIncome = false
  ) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.phoneNumber = phoneNumber;
    this.email = email;
    this.debts = debts;
    this.jobs = jobs;
    this.income = income;
    this.debt = debt;
    this.privacy = privacy;
    this.creditCheck = creditCheck;
    this.creditScore = creditScore;
    this.id = id;
    this.maritalStatus = maritalStatus;
    this.preNup = preNup;
    this.noDebt = noDebt;
    this.noMoreDebt = noMoreDebt;
    this.noIncome = noIncome;
    this.income = income;
  }
}
class Debt {
  constructor(
    payment,
    type = "",
    balance = 0,
    id = 0,
    deferred = "No",
    isDeferred = false
  ) {
    this.type = type;
    this.payment = payment;
    this.balance = balance;
    this.id = id;
    this.deferred = deferred;
    this.isDeferred = isDeferred;
  }
}
class Job {
  constructor(
    title = "",
    salary = 0,
    yearsOfExperience = 0,
    type = "nomina",
    paymentFrequency = "",
    id = 0,
    firstReturn = 0,
    secondReturn = 0,
    returnAverage = 0,
    pensionType = "",
    hourlyRate = 0,
    hoursWorked = 0
  ) {
    this.firstReturn = firstReturn;
    this.secondReturn = secondReturn;
    this.title = title;
    this.type = type;
    this.salary = salary;
    this.paymentFrequency = paymentFrequency;
    this.yearsOfExperience = yearsOfExperience;
    this.id = id;
    this.returnAverage = returnAverage;
    this.pensionType = pensionType;
    this.hourlyRate = hourlyRate;
    this.hoursWorked = hoursWorked;
  }
}
class Property {
  constructor(location = "", price = 0, address = "") {
    this.location = location;
    this.price = price;
    this.address = address;
  }
}

const Objects = { preQual, Client, Debt, Job, Property };

export default Objects;
