import DebtInfoForm from "./DebtInfo/DebtInfoForm";
import { useContext } from "react";
import PreQContext from "../../PreQContext";
const DebtInfoPage = (props) => {
  const value = useContext(PreQContext);
  return (
    <div className="debt-box">
      {value.preQual.clients.map((client) => (
        <DebtInfoForm
          locale={value.locale}
          preQual={value.preQual}
          setCompleted={value.setCompleted}
          client={client}
          id={Math.random()}
        />
      ))}
    </div>
  );
};

export default DebtInfoPage;
