import { useContext, useState } from "react";
import PreQContext from "../../PreQContext";
import ClientInfoForm from "./ClientInfo/ClientInfoForm";
import ClientInfoFormForm from "./ClientInfo/ClientInfoFormForm";
const AddSpouse = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const value = useContext(PreQContext);
  const [clients, setClients] = useState(value.preQual.clients);
  value.preQual.clients = clients;
  const clientClickHandler = () => setIsEditing(true);
  if (value.preQual.clients.length > 1) {
    return (
      <>
        {isEditing ? (
          <ClientInfoFormForm
            preQual={value.preQual}
            clients={clients}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            setClients={setClients}
          />
        ) : (
          <>
            <ClientInfoForm
              preQual={value.preQual}
              setCompleted={value.setCompleted}
            />
          </>
        )}
      </>
    );
  } else {
    return (
      <>
        {isEditing ? (
          <>
            <ClientInfoFormForm
              preQual={value.preQual}
              clients={clients}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              setClients={setClients}
            />
          </>
        ) : (
          <button className="add-spouse-button" onClick={clientClickHandler}>
            {value.locale === "en" ? "Add Spouse" : "Añadir Pareja"}
          </button>
        )}
      </>
    );
  }
};
export default AddSpouse;
