/* 
Imports
*/

import "./App.css";
import Objects from "./Objects";

import PreQContext from "./PreQContext";
import { useState } from "react";
import LoanPurpose from "./components/Questions/LoanPurpose";
import Landing from "./components/Questions/Landing";
import MaritalStatus from "./components/Questions/MaritalStatus";
import ClientInfoForm from "./components/Questions/ClientInfo/ClientInfoForm";
import VAQuestion from "./components/Questions/VAQuestion";
import JobInfoPage from "./components/Questions/JobInfoPage";
import DebtInfoPage from "./components/Questions/DebtInfoPage";
import CreditInfoPage from "./components/Questions/CreditInfoPage";
import FinalPage from "./components/UI/FinalPage";
import Prenup from "./components/Questions/Prenup";
import Header from "./components/UI/Header";
import MoveButtons from "./components/UI/MoveButtons";
import AddSpouse from "./components/Questions/AddSpouse";
import WillCall from "./components/UI/WillCall";
import "./components/UI/MainDisplay.css";

//Assigns passed originator or sets one randomly.
var originator;
function App(props) {
  if (props.originator) {
    originator = props.originator;
  } else {
    if (Math.random() > 0.5) {
      originator = "660";
    } else {
      originator = "660";
    }
  }

  //Sets language
  const [locale, setLocale] = useState("en");

  // !!! This determines wether a letter is generated at the end or not  !!!

  const letter = props.letter;

  //Initiates states

  const [preQual, setPreQual] = useState(new Objects.preQual(originator)); // new preQ always created, no modifications.
  const [completed, setCompleted] = useState(false); // When this is true the next button is available.
  const [currSlide, setSlide] = useState(0); // This determines what's being displayed.
  const setCurrSlide = (slide) => {
    // Changes the slide based on the array below and hides the next button.
    setCompleted(false);
    setSlide(slide);
  };

  // Slides that will be displayed

  const [slides] = useState([
    { slide: <LoanPurpose />, id: 0, prev: 0, next: 1 }, // Buy or refinance?
    { slide: <Landing />, id: 1, prev: 0, next: 2 }, // Disclaimers
    { slide: <ClientInfoForm />, id: 2, prev: 1, next: 3 }, // Contact Info
    { slide: <MaritalStatus />, id: 3, prev: 2, next: 4 }, // Marital status TODO: change for question slide
    { slide: <Prenup />, id: 4, prev: 3, next: 5 }, // Prenuptial agreement question TODO: change for question slide
    { slide: <AddSpouse />, id: 5, prev: 4, next: 6 },
    { slide: <VAQuestion />, id: 6, prev: 5, next: 7 }, // VA TODO: change for question slide
    { slide: <JobInfoPage />, id: 7, prev: 6, next: 8 }, // Income - Change for form slide
    { slide: <DebtInfoPage />, id: 8, prev: 7, next: 9 }, // Debt -- change for form slide
    { slide: <CreditInfoPage />, id: 9, prev: 8, next: 10 }, // Credit
    { slide: <FinalPage />, id: 10, prev: 9, next: 10 }, // Totals shown here
    {
      slide: <WillCall />, // Final display here
      id: 11,
      prev: 10,
      next: 11,
    },
  ]);

  // Setters for next and prev slides based on answers for dynamic flow control.

  const setPrev = (id, prev) => {
    slides[id].prev = prev;
  };
  const setNext = (id, next) => {
    slides[id].next = next;
  };

  return (
    <>
      <PreQContext.Provider
        value={{
          preQual,
          setPreQual,
          completed,
          setCompleted,
          setPrev,
          setNext,
          slides,
          currSlide,
          setCurrSlide,
          letter,
          locale,
        }}
      >
        <>
          <Header locale={locale} setLocale={setLocale} />
          <div className="maindisplay">
            {slides[currSlide].slide}
            <MoveButtons />
          </div>
        </>
      </PreQContext.Provider>
    </>
  );
}

export default App;
