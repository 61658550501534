import { useState } from "react";
import DebtButton from "./DebtButton";
import DebtInfoFormForm from "./DebtInfoFormForm";
import Card from "../../UI/Card";

const DebtInfoForm = (props) => {
  // console.log(props);

  const [debts, setDebts] = useState(props.client.debts);
  props.client.debts = debts;

  const addDebtHandler = () => {
    setIsEditing(true);
  };
  const [noDebt, setNoDebt] = useState(props.client.noDebt);
  const [noMoreDebt, setNoMoreDebt] = useState(props.client.noMoreDebt);
  const [isEditing, setIsEditing] = useState(false);
  const noMoreDebtHandler = (event) => {
    if (noMoreDebt === false) {
      setNoMoreDebt(true);
    } else {
      setNoMoreDebt(false);
    }
  };
  const noDebtHandler = (event) => {
    if (noDebt) {
      setNoDebt(false);
    } else {
      setNoDebt(true);
    }
  };
  props.client.noDebt = noDebt;
  props.client.noMoreDebt = noMoreDebt;
  for (let i = 0; i < props.preQual.clients.length; i++) {
    if (
      !props.preQual.clients[i].noDebt &&
      !props.preQual.clients[i].noMoreDebt
    ) {
      props.setCompleted(false);
      break;
    } else {
      props.setCompleted(true);
    }
  }
  if (props.client.debts.length === 0) {
    if (isEditing) {
      return (
        <DebtInfoFormForm
          locale={props.locale}
          key={Math.random()}
          setIsEditing={setIsEditing}
          client={props.client}
          setDebts={setDebts}
          setCompleted={[props.setCompleted]}
        />
      );
    } else {
      return (
        <Card className={"debt-card"}>
          <button className="add-debt-button" onClick={addDebtHandler}>
            {props.locale === "en" ? "Add debts of " : "Añadir deuda"}{" "}
            {props.client.firstName}
            <br />
          </button>
          {props.client.debts.length > 0 && (
            <p className="final-header">
              {props.locale === "en" ? "Monthly Payments:" : "Pagos Mensuales:"}
            </p>
          )}
          {props.client.debts.length === 0 ? (
            <div onClick={noDebtHandler} className="no-debt-checkbox-label">
              <input
                className="no-debt-checkbox"
                type="checkbox"
                style={{
                  outline: noDebt ? "5px solid green" : "",
                }}
                checked={noDebt ? "checked" : ""}
                onChange={noDebtHandler}
              />
              {props.client.firstName}{" "}
              {props.locale === "en" ? "has no debts" : "no tiene deudas"}
              {"    "}
            </div>
          ) : (
            <></>
          )}
        </Card>
      );
    }
  } else {
    return (
      <>
        <Card className={"debt-card"}>
          <button className="add-debt-button" onClick={addDebtHandler}>
            {props.locale === "en" ? "Add debts for " : "Añadir deuda"}{" "}
            {props.client.firstName}
            <br />
          </button>
          {props.client.debts.length > 0 ? (
            props.locale === "en" ? (
              <p className="question-text">Monthly payments:</p>
            ) : (
              <p className="question-text">Pagos mensuales:</p>
            )
          ) : (
            ""
          )}
          <br />
          <>
            {props.client.debts.map((debt) => (
              <DebtButton
                locale={props.locale}
                debt={debt}
                key={debt.id}
                debts={debts}
                setDebts={setDebts}
              />
            ))}

            <div onClick={noMoreDebtHandler} className="no-debt-checkbox-label">
              <input
                className="no-debt-checkbox"
                type="checkbox"
                style={{
                  outline: noMoreDebt ? "5px solid green" : "5px solid red",
                }}
                checked={noMoreDebt ? "checked" : ""}
                onChange={noMoreDebtHandler}
              />
              {props.client.firstName}{" "}
              {props.locale === "en"
                ? "only has the debts listed here"
                : "solo tiene las deudas indicadas aquí"}
            </div>
          </>
          {isEditing ? (
            <DebtInfoFormForm
              locale={props.locale}
              key={Math.random()}
              setIsEditing={setIsEditing}
              client={props.client}
              setDebts={setDebts}
            />
          ) : (
            <></>
          )}
        </Card>
      </>
    );
  }
};

export default DebtInfoForm;
