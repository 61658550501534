import { useState, useContext } from "react";
import PreQContext from "../../PreQContext";
import Question from "../UI/Question";

const preNupButtons = [
  { value: "true", text: "Si" },
  { value: "false", text: "No" },
];
const enPreNupButtons = [
  { value: "true", text: "Yes" },
  { value: "false", text: "No" },
];
const Prenup = (props) => {
  const value = useContext(PreQContext);
  const [enteredPreNup, setPreNup] = useState(value.preQual.clients[0].preNup);
  if (value.preQual.clients[0].preNup !== null) {
    value.setCompleted(true);
  }
  const preNupClickHandler = (event) => {
    value.preQual.clients[0].preNup = event.target.value;
    if (event.target.value === "true") {
      setPreNup(true);
      value.setNext(4, 6); // If prenup, no need to add spouse
      value.setPrev(6, 4);
      value.setCurrSlide(6);
    } else {
      setPreNup(false);
      value.setNext(4, 5); // Sets back in case changed before.
      value.setPrev(6, 5);
      value.setCurrSlide(5);
    }
  };
  return (
    <>
      <div className="marital-status-box">
        <Question
          questionText={
            value.locale === "en"
              ? "Do you have a prenuptial agreement?"
              : "¿Tienen Capitulaciones?"
          }
          value={enteredPreNup}
          buttons={value.locale === "en" ? enPreNupButtons : preNupButtons}
          clickHandler={preNupClickHandler}
        />
      </div>
    </>
  );
};

export default Prenup;
