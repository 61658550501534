import { useState } from "react";
import DebtEditForm from "./DebtEditForm.js";

const translateDebtType = (type) => {
  switch (type) {
    case "Tarjeta de Credito":
      return "Credit Card";
    case "Prestamo de Auto":
      return "Auto Loan";
    case "Prestamo Personal":
      return "Personal Loan";
    case "Prestamo Estudiantil":
      return "Student Loan";
    case "Pension":
      return "Alimony";
    case "Hipoteca":
      return "Mortgage";
  }
};

const DebtButton = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const editHandler = () => {
    setIsEditing(true);
  };

  const removeHandler = () => {
    props.setDebts((debts) =>
      debts.filter((debt) => debt.id !== props.debt.id)
    );
    console.log(props.debts);
  };

  return (
    <>
      {isEditing ? (
        <DebtEditForm
          locale={props.locale}
          debts={props.debts}
          debt={props.debt}
          setIsEditing={setIsEditing}
          setDebts={props.setDebts}
        />
      ) : (
        <p>
          <span className="job-info">
            {props.locale === "en"
              ? translateDebtType(props.debt.type)
              : props.debt.type}
            :{" "}
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.debt.payment)}
            {props.locale === "en" ? " monthly" : " mensual"}
          </span>
          <span className="edit-button modify-info-job" onClick={editHandler}>
            ✎
          </span>
          <span className="edit-button delete-job" onClick={removeHandler}>
            🗑
          </span>
          <br />
        </p>
      )}
    </>
  );
};

export default DebtButton;
