import "../Questions/ClientInfo/ClientButton.css";
import "../Questions/ClientInfo/ClientInfoForm.css";
function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}
export default function DisplayClientButton(props) {
  return (
    <>
      <>
        <p className="nameLastName">
          {props.client.firstName} {props.client.lastName}{" "}
        </p>
        <br></br>
        <p id="phoneEmail">
          ☎ {formatPhoneNumber(props.client.phoneNumber)} 📧
          {props.client.email}
        </p>
      </>
      <br />
      <p className="final-header">
        Puntuación de crédito: {props.client.creditScore}
      </p>
      <>
        {props.client.jobs.length > 0 ? (
          <p className="final-header">
            Ingreso:{" "}
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.client.income)}{" "}
            mensual{" "}
          </p>
        ) : (
          <p className="job-info">
            {" "}
            {props.client.firstName} no tiene ingresos.{" "}
          </p>
        )}
      </>
      <>
        {props.client.debts.length > 0 ? (
          <p className="final-header">
            Deuda:{" "}
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.client.debt)}{" "}
            mensual{" "}
          </p>
        ) : (
          <p className="final-header">
            {" "}
            {props.client.firstName} no tiene deudas.{" "}
          </p>
        )}
      </>
    </>
  );
}
